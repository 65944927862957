import React from "react";
import footerIcon from "./img/32 Copy 2.svg";
import * as S from "./footer.styled";
import { FooterCMS } from "./cms";

const AnonymousFooter = (props) => {
  const { app, links } = FooterCMS();

  return (
    <S.Container {...props}>
      <S.LogoContent>
        <S.Img src={footerIcon} alt={"compass"} />
        <S.Logo>{app}</S.Logo>
      </S.LogoContent>
      <S.FooterNav>
        <S.Link to="/about" label={links.about_us} aria-label={links.about_us}>
          {links.about_us}
        </S.Link>
        <S.Link
          to="/system-stats"
          label="System Stats"
          aria-label={`System Stats`}
        >
          {links.our_impact}
        </S.Link>
        <S.Link
          to="/terms-of-use"
          label={links.terms_of_use}
          aria-label={links.terms_of_use}
        >
          {links.terms_of_use}
        </S.Link>
        <S.Link
          to="/privacy-policy"
          label={links.privacy_policy}
          aria-label={links.privacy_policy}
        >
          {links.privacy_policy}
        </S.Link>
        <S.Link to="/faq" label={links.faq} aria-label={links.faq}>
          {links.faq}
        </S.Link>
        <S.Link
          to="/contact-us"
          label={links.contact_us}
          aria-label={links.contact_us}
        >
          {links.contact_us}
        </S.Link>
      </S.FooterNav>
      {/* Display the togetherNowStamp string */}
      {/* Display the togetherNowWhite image */}
      <S.ImageLogo>
        <p>{links.togetherNowStamp}</p>
        {links.togetherNowIconWhite && (
          <img
            alt="Together Now logo"
            src={`${process.env.REACT_APP_STRAPI_ENDPOINT}${links.togetherNowIconWhite}`}
          />
        )}
      </S.ImageLogo>
    </S.Container>
  );
};

export default AnonymousFooter;
