import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useTimeoutCMS = () => {
  const init = {
    text_timed_out: "You're being timed out due to inactivity.",
    text_redirect: "You'll now be redirected to the login page.",
    text_warning: "You will be logged out in [remaining_time] seconds due to inactivity",
    text_warning_heading: "Are you still using MyWayfinder?",
    button_warning_exit: "Exit",
    button_warning_exit_description: "and log out of MyWayfinder",
    button_warning_exit_description_aria: "to sign off MyWayFinder",
    button_warning_continue: "Yes",
    button_warning_continue_description: "stay logged in to MyWayfinder",
    button_warning_continue_description_aria: "to continue your MyWayFinder session",
    text_expired_session: "Your Session has Expired",
    text_session_timeout_warning: "Session Timeout Warning",
    text_minutes: "minute",
    text_and: "and",
    text_seconds: "seconds"
  };

  const { data, loading } = useStrapi({
    cms: `modal-timeout`
  });

  const [cms, setCMS] = useState(init);

  useEffect(() => {
    if (loading) {
      setCMS(init);
      return;
    }
    setCMS((state) => ({
      text_timed_out: data.text_timed_out || state.text_timed_out,
      text_redirect: data.text_redirect || state.text_redirect,
      text_warning: data.text_warning || state.text_warning,
      text_warning_heading: data.text_warning_heading || state.text_warning_heading,
      button_warning_exit: data.button_warning_exit || state.button_warning_exit,
      button_warning_exit_description: data.button_warning_exit_description || state.button_warning_exit_description,
      button_warning_exit_description_aria: data.button_warning_exit_description_aria || state.button_warning_exit_description_aria,
      button_warning_continue: data.button_warning_continue || state.button_warning_continue,
      button_warning_continue_description: data.button_warning_continue_description || state.button_warning_continue_description,
      button_warning_continue_description_aria: data.button_warning_continue_description_aria || state.button_warning_continue_description_aria,
      text_expired_session: data.text_expired_session || state.text_expired_session,
      text_session_timeout_warning: data.text_session_timeout_warning || state.text_session_timeout_warning,
      text_minutes: data.text_minutes || state.text_minutes,
      text_and: data.text_and || state.text_and,
      text_seconds: data.text_seconds || state.text_seconds
    }));
  }, [data]);

  return { cms };
};
