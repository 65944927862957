import React, { useState, useEffect, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";

export const ActivityFeedContext = React.createContext([]);

export const ActivityFeedProvider = ({ children }) => {
  const { user } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  const [activityFeed, setActivityFeed] = useState(
    user?.activityFeed ? [...user?.activityFeed] : []
  );
  
  const [numberUnread, setNumberUnread] = useState({ total: 0, "care-team": 0, referral: 0 });

  useEffect(() => {
    socket.emit("activities:subscribe", { id: user.id }, (data) => {
      if (data) {
        let unread = {
          total: data?.unread || 0,
          "care-team": data?.careTeamTotal || 0,
          referral: data?.serviceRequestTotal || 0
        }
        setNumberUnread(unread)
      }
    });
    socket.on("activities:new", (data) => {
      setNumberUnread((prevState) => {
        let newState = {
          ...prevState,
          [data.newType]: prevState[data.newType] + 1
        };
        return newState;
      });

      if (window?.location?.pathname?.includes("/feed")) {
        getFeed(() => {});
      }
    });
  }, []);

  const getLatestFeed = () => {
    // socket.emit("activities:latest", { id: user.id }, (data) => {
    //   updateFeed(data.activityFeed);
    //   return data.activityFeed
    // });
  };

  const getFeed = async (cb) => {
    const data = await apiService.get('/notifications')
    updateFeed(data.notifications);
    cb()
  };

  const updateFeed = (updatedFeed) => {
    // Preserves activity feed in user context as well as localstorage
    setActivityFeed(updatedFeed);
  };

  const clearActivity = (notifid, ntype) => {
    const updatedFeed = activityFeed.filter((f) => f.id !== notifid);
    updateFeed(updatedFeed);
    socket.emit("activities:clear", {
      id: user.id,
      cardId: notifid,
      ntype: ntype,
      isProvider: false,
    });
  };

  const clearReferralActivity = async (
    referralId,
    ntype = "referral",
    stype = "created",
    currentCard
  ) => {

    const socketClear = (userId, cardId, ntype, stype, isProvider = false) => {
      socket.emit("activities:clear", { 
        id: userId, 
        cardId, 
        ntype, 
        stype,
        isProvider 
      });
    }

    const updatedFeed = activityFeed.filter((f) => {
      // clear from database and filter out:
      // matching referral card and/or optionally current card
      if (
        (f.referralId === referralId &&
          f.type === ntype &&
          f.subType === stype) ||
          (currentCard && f.id === currentCard.id)
      ) {
        socketClear(user.id, f.id, f.type, f.subType, false)
      }
      // filter out target referral activities from feed
      return !(
        (f.referralId === referralId &&
        f.type === ntype &&
        f.subType === stype) ||
        (currentCard && f.id !== currentCard.id)
      );
    });
    updateFeed(updatedFeed);
  };

  const readFeedType = (type) => {
    const newNumberUnread = {
      ...numberUnread,
      [type]: 0,
    };
    setNumberUnread(newNumberUnread);
    socket.emit("activities:read", { id: user.id, type });
  };

  const getTotalUnread = () => {
    let total = 0;
    for (const [key, val] of Object.entries(numberUnread)){
      if(key !== "total" && !isNaN(val)){
        total += val
      }
    }
    return total;
  }

  const setActivityInactive = async (notifid, ntype) => {
    const updatedFeed = activityFeed.map((f) => {
      if (f.id === notifid) {
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });
    updateFeed(updatedFeed);
    socket.emit(
      "activities:setinactive",
      {
        id: user.id,
        cardId: notifid,
        ntype: ntype,
        isProvider: false,
      },
      () => {
        return;
      }
    );
  };

  const setReferralActivityInactive = async (
    referralId,
    clientId,
    ntype,
    stype,
    currentCard
  ) => {

    const socketInactivate = (userId, cardId, ntype, isProvider) => {
      socket.emit(
        "activities:setinactive",
        { id: userId, cardId, ntype, isProvider },
        (data) => { return; }
      );
    }

    const updatedFeed = activityFeed.map((f) => {
      if (
        (f.referralId === referralId &&
          f.type === ntype &&
          f.subType === stype) ||
        (currentCard && f.id === currentCard.id)
      ) {
        // matching referral card (and optionally current card if id is provided) is inactivated in database
        socketInactivate(user.id, f.id, f.type, false)
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });

    // clear out card if it's an expire-warning card
    // if(currentCard && currentCard.subType === 'expire-warning') {
    //     updatedFeed = updatedFeed.filter(f => f.id !== currentCard.id)
    //     socket.emit("activities:clear", { 
    //       id: user.id, 
    //       cardId: currentCard.id, 
    //       ntype: ntype, 
    //       isProvider: false 
    //     });
    //   }
    updateFeed(updatedFeed);
  };

  const setReferralActivitiesInactive = async (
    clientId,
    referralId,
    ntype="referral"
  ) => {

    const socketInactivate = (userId, cardId, ntype, isProvider) => {
      socket.emit(
        "activities:setinactive",
        { id: userId, cardId, ntype, isProvider },
        (data) => { return; }
      );
    }

    let updatedFeed = activityFeed.map((f) => {
      // collect associated referral cards
      if (
        f.referralId === referralId &&
          f.type === ntype
      ) {
        // matching referral cards inactivated in database
        socketInactivate(user.id, f.id, f.type, false)
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });
    updateFeed(updatedFeed);
  };

  const setCareTeamActivityInactive = (
    serviceId,
    locationId,
    clientId,
    ntype,
    stype,
    cardId
  ) => {
    let updatedFeed = activityFeed.map((f) => {
      if (
        f.serviceId === serviceId &&
        f.locationId === locationId &&
        f.type === ntype &&
        f.subType === stype
      ) {
        return { ...f, active: 0 };
      } else {
        return { ...f };
      }
    });

    // Filter out cards that are duplicates (except for current card)
    updatedFeed = updatedFeed.filter((f) => {
      if (
        f.serviceId === serviceId &&
        f.locationId === locationId &&
        f.type === ntype &&
        f.subType === stype &&
        f.id !== cardId
      ) {
        return false; 
      } else {
        return true;
      }

    })
    updateFeed(updatedFeed);
    socket.emit(
      "activities:setclientcareteaminactive",
      {
        serviceId,
        locationId,
        clientId,
        ntype,
        stype,
        cardId
      },
      () => {
        return;
      }
    );    
  };

  return (
    <ActivityFeedContext.Provider
      value={{
        activityFeed,
        numberUnread,
        readFeedType,
        getLatestFeed,
        clearActivity,
        clearReferralActivity,
        setActivityInactive,
        getTotalUnread,
        getFeed,
        setCareTeamActivityInactive,
        setReferralActivityInactive,
        setReferralActivitiesInactive
      }}
    >
      {children}
    </ActivityFeedContext.Provider>
  );
};

export const ActivityFeedConsumer = ActivityFeedContext.Consumer;
