export const theme = {
  colors: {
    primary: {
      darkBlue: "rgba(16,6,146,1)", //#005d83
      mediumBlue: "rgba(0,145,179,1)", //#0091b3
      lightBlue: "rgba(74,193,224,1)", //#4ac1e0
      mossGreen: "rgba(6,170,90,1)", //#72a94e
      lightGreen: "rgba(147,231,0,1)", //#93d500
      white: "rgba((246,246,246))"
    },
    accent: {
      darkBlue: "#337d9c", //#005d83
      mediumBlue: "#33a7c2", //#0091b3
      lightBlue: "#3b9ab3", //#4ac1e0
      mossGreen: "#8eba71", //#72a94e
      lightGreen: "#76aa00", //#93d500
      white: "#F6F6F6"
    }
  },
  breakpoints: {
    xxxl: `(min-width: 1920px)`,
    xxl: `(min-width: 1600px)`,
    xl: `(min-width: 1440px)`,
    lg: `(min-width: 1280px)`,
    md: `(min-width: 1024px)`,
    sm: `(min-width: 768px)`,
    xs: `(min-width: 540px)`,
    xxs: `(min-width: 375px)`
  },
  button: {
    cancel: {
      color: "#ffffff",
      bg: "#000000",
      hover: "#222222"
    }
  }
};
