import { useContext, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { InlineLoading } from "carbon-components-react";

import kit from "ich-ui-kit";

import { usePrivacyPolicyCMS } from "../LegalPages/cms";
import * as S from "./modal-privacyPolicy.styled";
export const PrivacyPolicyUpdateModal = () => {
  const { ppCMS } = usePrivacyPolicyCMS();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { user, updateUser } = useContext(kit.UserContext);
  const [loadingPrivacy, setLoadingPrivacy] = useState(false);
  useLayoutEffect(() => {
    if (
      location.pathname === "/privacy-policy" ||
      location.pathname === "/login"
    )
      return setShow(false);
    setShow(true);
  }, [location]);
  return (
    <S.Modal
      modalHeading={ppCMS.heading_we_updated}
      open={show}
      preventCloseOnClickOutside={true}
      hasScrollingContent={true}
      modalAriaLabel={ppCMS.heading_we_updated}
      aria-label={ppCMS.heading_we_updated}
      primaryButtonText={
        loadingPrivacy ? (
          <InlineLoading description="Acknowledging..." />
        ) : (
          ppCMS.btn_close
        )
      }
      onRequestSubmit={async () => {
        try {
          setLoadingPrivacy(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/account/user/privacy-policy`,
            {
              method: "put",
              headers: {
                Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                id: user.id,
                policyStatus: true
              })
            }
          );
          if (response.status !== 200) throw new Error("Something went wrong");
          setShow(false);
          setTimeout(() => {
            updateUser({
              ...user,
              policy_accepted: 1
            });
          }, 1000);
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingPrivacy(false);
        }
        setLoadingPrivacy(false);
      }}
      size="sm"
    >
      <S.ModalBody>
        <h4>
          {ppCMS.text_updated_as_of} {ppCMS.effective_date}
        </h4>
        <S.LockIcon>
          <S.ViewContainer href="/privacy-policy" target="_blank" rel="noreferrer noopener">
            <S.Locked id="LockedIon" />
            <label
              htmlFor="LockedIcon"
              style={{
                fontSize: "1.25rem",
                cursor: "pointer"
              }}
            >
              {ppCMS.btn_view}
            </label>
          </S.ViewContainer>
        </S.LockIcon>
      </S.ModalBody>
    </S.Modal>
  );
};
