import React, { useState } from "react";

export const LocaleContext = React.createContext({});

export const LocaleProvider = ({ children }) => {

  let defaultLocale = {
    code: "en",
    name: "English (en)",
    isDefault: true
  };

  let savedLocale = localStorage.getItem("locale");
  let selectedLocale = savedLocale ? JSON.parse(savedLocale): defaultLocale;
  if(!savedLocale) {
    localStorage.setItem("locale", JSON.stringify(defaultLocale))
  }

  const [locale, setLocale] = useState(selectedLocale);
  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <>{children}</>
    </LocaleContext.Provider>
  );
};

export const LocaleConsumer = LocaleContext.Consumer;
