import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useAccountCMS = () => {
  const init = {
    user_information: {
      heading_user_information: "Profile Information",
      subheading_account_info: "Account Information",
      heading_demographic: "About Me",
      label_name: "Name",
      label_language: "Language",
      label_email: "Email",
      subheading_profile_photo: "Profile Photo",
      text_upload_profile_image: "Upload Profile Image",
      text_max_size: "Maximum file size: 3MB",
      text_supported_ext: "Only .jpg and .png files are supported",
      btn_upload: "Upload",
      btn_delete: "Delete",
      btn_deleting: "Deleting",
      btn_change_password: "Change Password",
      btn_submit: "Submit",
      btn_cancel: "Cancel",
      warning_password_change: "You will be logged out of MyWayfinder after changing your password. You will need to log in again with your new password.",
      label_current_password: "Current Password",
      label_new_password: "New Password",
      label_confirm_password: "Confirm Password",
      label_invalid_password: "Invalid Password!",
      label_try_again: "Please try again",
      success: "Success",
      success_password_change: "Password Changed Successfully!",
      btn_save: "Save",
      btn_saving: "Saving",
      text_deleted_profile_image: "You have deleted your profile image",
      text_clear_file: "Clear file"
    },
    consents: {
      heading_manage_consents: "Manage Consents",
      text_rev_and_upd:
        "Review and update the information you share with your Care Team.",
      text_empty:
        "You don't have anyone on your Care Team! Click below to find providers who can help you.",
      btn_services: "Find Services",
      tableheader_care_team: "Care Team",
      tableheader_update_consent: "Update Consent",
      tooltip_remove_from_careteam: "Remove from Care Team",
      btn_deactivate: "Deactivate MyWayfinder Account",
      modal_deactivate: "Thank you for trusting MyWayfinder! Your account has been deactivated. You will be redirected to login shortly.",
      modal_deac_text: "Thank you for trusting MyWayfinder! Your account has been deactivated. You will be redirected to login shortly.",
      modal_deactivate_warning: "Deactivating your account will clear your current care team and you will need to add them back should you choose to return. This will not erase your data but providers will no longer have access to it.",
      modal_are_you_sure: "Are you sure?",
      modal_btn_deactivate: "Deactivate",
      modal_btn_deactivating: "Deactivating",
      modal_btn_cancel: "Cancel",
      title_confirm_enrollment: "Confirm MyWayfinder Enrollment",
      desc_account_created: "Your account was created by [provider_name] with [org_name]. They’ve also added themselves to your Care Team to help with [careteam_name]",
      desc_continue: "Click continue to confirm your account and keep working with your Care Team. You can add or remove providers from your Care Team at any time.",
      btn_continue: "Continue",
      title_cancel_enrollment: "Cancel Enrollment",
      desc_cancel_enrollment: "If you no longer wish to have a MyWayfinder account, you can cancel it",
      btn_cancel: "Cancel",
      prompt_update_password: "Before continuing, please update your password.",
      text_set_new_password: "Set a new password",
      text_confirm_password: "Confirm your password",
      btn_reset_password: "Reset Password",
      label_show_password: "Show password",
      label_hide_password: "Hide password",
      error: "Error",
      error_changing_password: "Error Changing Password",
      error_previous_passwords: "Previous passwords cannot be reused."

    },
    notifications: {
      heading_notification_preferences: "Notification Preferences",
      subheading_update_account: "Update Account Information Preferences",
      subheading_advanced_access: "Advanced Access Authentication Preferences",
      label_email: "Email",
      label_text: "Text",
      text_receive_notif:
        "Receive notifications about activity across your account, such as referrals and messages from your care team.",
      text_opt_in:
        "You can opt-in to receive email notifications about your account.",
      text_receive_email:
        "Receive information such as referral updates via email",
      text_advanced_access:
        "Advanced Access Authentication is the security tool that generates the verification code you use to login to your account. You can opt-in to receive an email or a text message. Advanced Access Authentication is required and only one option can be selected.",
      text_receive_ver_email: "Receive verification code via e-mail.",
      text_receive_ver_text: "Receive verification code via text messages",
      toggle_on: "On",
      toggle_off: "Off"
    },
    badges: {
      heading_badges: "Badges",
      subheading_earned_badges: "Earned Badges",
      subheading_available_badges: "Available Badges"
    },
    invite: {
      heading_invite: "Invite",
      subheading_invite_f_and_f: "Invite Family and Friends",
      text_enter_email_phone:
        "Enter the email, phone number, and birthdate for the individual you want to invite.",
      label_email: "Email",
      label_phone: "Phone",
      btn_invite: "Invite",
      btn_sending: "Sending",
      tableheader_date_time: "Date/Time",
      tableheader_invitee: "Invitee",
      placeholder_invite_family:
        'Invite a family member or friend to earn the "Refer a Friend" badge!',
      label_remove: "Remove",
      label_first_name: "First Name",
      label_last_name: "Last Name",
      label_relationship: "Relationship",
      label_select_one: "Select one",
      label_dob: "Date of Birth",
      label_related_person: "Add Related Person",
      prompt_valid_number: "Please enter a valid number",
      prompt_valid_dob: "Individuals must be 13 or older to create an account.",
      label_success: "Success",
      label_success_subtitle: "Your message has been sent successfully.",
      label_error: "Error",
      label_error_subtitle: "An error has occured. Please try again later.",
      relation_significant_other: "Significant Other",
      relation_spouse: "Spouse",
      relation_former_spouse: "Former Spouse",
      relation_sibling: "Sibling",
      relation_dependent: "Dependent",
      relation_extended_family: "Extended Family Member",
      relation_parent: "Parent",
      relation_neighbor: "Neighbor",
      relation_roommate: "Roommate",
      relation_unrelated_friend: "Friend",
      relation_rep_payee: "Representative Payee",
      relation_guardian: "Guardian",
      relation_conservator: "Conservator",
      relation_attorney: "Power of Attorney",
      relation_ward_of_court: "Ward of Court",
      relation_other: "Other",
      header_name: "Name",
      header_status: "Status",
      header_relationship: "Relationship",
      status_invited: "Invited",
      status_added: "Added"
    }
  };
  const { data, loading } = useStrapi({
    cms: `page-profile-dashboard`
  });
  const [account, setAccount] = useState(init);
  useEffect(() => {
    if (loading) {
      setAccount(init);
      return;
    }
    setAccount((state) => ({
      user_information: {
        subheading_account_info:
          data.user_information.subheading_account_info ||
          state.user_information.subheading_account_info,
        heading_demographic:
          data.user_information.heading_demographic ||
          state.user_information.heading_demographic,
        label_name:
          data.user_information.label_name || state.user_information.label_name,
        label_language:
          data.user_information.label_language ||
          state.user_information.label_language,
        label_email:
          data.user_information.label_email ||
          state.user_information.label_email,
        subheading_profile_photo:
          data.user_information.subheading_profile_photo ||
          state.user_information.subheading_profile_photo,
        text_upload_profile_image: data.user_information.text_upload_profile_image || state.user_information.text_upload_profile_image,
        text_max_size:
          data.user_information.text_max_size ||
          state.user_information.text_max_size,
        text_supported_ext:
          data.user_information.text_supported_ext ||
          state.user_information.text_supported_ext,
        btn_upload:
          data.user_information.btn_upload || state.user_information.btn_upload,
        btn_delete:
          data.user_information.btn_delete || state.user_information.btn_delete,
        btn_deleting: data.user_information.btn_deleting || state.user_information.btn_deleting,
        btn_change_password:
          data.user_information.btn_change_password ||
          state.user_information.btn_change_password,
        heading_user_information:
          data.user_information.heading_user_information ||
          state.user_information.heading_user_information,
        btn_submit: data.user_information.btn_submit || state.user_information.btn_submit,
        btn_cancel: data.user_information.btn_cancel || state.user_information.btn_cancel,
        warning_password_change: data.user_information.warning_password_change || state.user_information.warning_password_change,
        label_current_password: data.user_information.label_current_password || state.user_information.label_current_password,
        label_new_password: data.user_information.label_new_password || state.user_information.label_new_password,
        label_confirm_password: data.user_information.label_confirm_password || state.user_information.label_confirm_password,
        label_invalid_password: data.user_information.label_invalid_password || state.user_information.label_invalid_password,
        label_try_again: data.user_information.label_try_again || state.user_information.label_try_again,
        success: data.user_information.success || state.user_information.success,
        success_password_change: data.user_information.success_password_change || state.user_information.success_password_change,
        btn_save: data.user_information.btn_save || state.user_information.btn_save,
        btn_saving: data.user_information.btn_saving || state.user_information.btn_saving,
        text_deleted_profile_image: data.user_information.text_deleted_profile_image || state.user_information.text_deleted_profile_image
      },
      consents: {
        heading_manage_consents:
          data.consents.heading_manage_consents ||
          state.consents.heading_manage_consents,
        text_rev_and_upd:
          data.consents.text_rev_and_upd || state.consents.text_rev_and_upd,
        text_empty: data.consents.text_empty || state.consents.text_empty,
        btn_services: data.consents.btn_services || state.consents.btn_services,
        tableheader_care_team:
          data.consents.tableheader_care_team ||
          state.consents.tableheader_care_team,
        tableheader_update_consent:
          data.consents.tableheader_update_consent ||
          state.consents.tableheader_update_consent,
        tooltip_remove_from_careteam:
          data.consents.tooltip_remove_from_careteam ||
          state.consents.tooltip_remove_from_careteam,
        modal_deactivate:
          data.consents.modal_deactivate || state.consents.modal_deactivate,
        modal_deac_text:
          data.consents.modal_deac_text || state.consents.modal_deac_text,
        btn_deactivate: data.consents.btn_deactivate || state.consents.btn_deactivate,
        modal_deactivate_warning: data.consents.modal_deactivate_warning || state.consents.modal_deactivate_warning,
        modal_are_you_sure: data.consents.modal_are_you_sure || state.consents.modal_are_you_sure,
        modal_btn_deactivate: data.consents.modal_btn_deactivate || state.consents.modal_btn_deactivate,
        modal_btn_deactivating: data.consents.modal_btn_deactivating|| state.consents.modal_btn_deactivating,
        modal_btn_cancel: data.consents.modal_btn_cancel || state.consents.modal_btn_cancel,
        title_confirm_enrollment: data.consents.title_confirm_enrollment || state.consents.title_confirm_enrollment,
        desc_account_created: data.consents.desc_account_created || state.consents.desc_account_created,
        desc_continue: data.consents.desc_continue || state.consents.desc_continue,
        btn_continue: data.consents.btn_continue || state.consents.btn_continue,
        title_cancel_enrollment: data.consents.title_cancel_enrollment || state.consents.title_cancel_enrollment,
        desc_cancel_enrollment: data.consents.desc_cancel_enrollment || state.consents.desc_cancel_enrollment,
        btn_cancel: data.consents.btn_cancel || state.consents.btn_cancel,
        prompt_update_password: data.consents.prompt_update_password || state.consents.prompt_update_password,
        text_set_new_password: data.consents.text_set_new_password || state.consents.text_set_new_password,
        text_confirm_password: data.consents.text_confirm_password || state.consents.text_confirm_password,
        btn_reset_password: data.consents.btn_reset_password || state.consents.btn_reset_password,
        label_show_password: data.consents.label_show_password || state.consents.label_show_password,
        label_hide_password: data.consents.label_hide_password || state.consents.label_hide_password,
        error: data.consents.error || state.consents.error,
        error_changing_password: data.consents.error_changing_password || state.consents.error_changing_password,
        error_previous_passwords: data.consents.error_previous_passwords || state.consents.error_previous_passwords,
      },
      notifications: {
        heading_notification_preferences:
          data.notifications.heading_notification_preferences ||
          state.notifications.heading_notification_preferences,
        subheading_update_account:
          data.notifications.subheading_update_account ||
          state.notifications.subheading_update_account,
        subheading_advanced_access:
          data.notifications.subheading_advanced_access ||
          state.notifications.subheading_advanced_access,
        label_email:
          data.notifications.label_email || state.notifications.label_email,
        label_text:
          data.notifications.label_text || state.notifications.label_text,
        text_receive_notif:
          data.notifications.text_receive_notif ||
          state.notifications.text_receive_notif,
        text_opt_in:
          data.notifications.text_opt_in || state.notifications.text_opt_in,
        text_receive_email:
          data.notifications.text_receive_email ||
          state.notifications.text_receive_email,
        text_advanced_access:
          data.notifications.text_advanced_access ||
          state.notifications.text_advanced_access,
        text_receive_ver_email:
          data.notifications.text_receive_ver_email ||
          state.notifications.text_receive_ver_email,
        text_receive_ver_text:
          data.notifications.text_receive_ver_text ||
          state.notifications.text_receive_ver_text,
        toggle_on:
          data.notifications.toggle_on || state.notifications.toggle_on,
        toggle_off:
          data.notifications.toggle_off || state.notifications.toggle_off
      },
      badges: {
        heading_badges:
          data.badges.heading_badges || state.badges.heading_badges,
        subheading_earned_badges:
          data.badges.heading_badges || state.badges.heading_badges,
        subheading_available_badges:
          data.badges.subheading_available_badges ||
          state.badges.subheading_available_badges
      },
      invite: {
        heading_invite:
          data.invite.heading_invite || state.invite.heading_invite,
        subheading_invite_f_and_f:
          data.invite.subheading_invite_f_and_f ||
          state.invite.subheading_invite_f_and_f,
        text_enter_email_phone:
          data.invite.text_enter_email_phone ||
          state.invite.text_enter_email_phone,
        label_email: data.invite.label_email || state.invite.label_email,
        label_phone: data.invite.label_phone || state.invite.label_phone,
        btn_invite: data.invite.btn_invite || state.invite.btn_invite,
        tableheader_date_time:
          data.invite.tableheader_date_time ||
          state.invite.tableheader_date_time,
        tableheader_invitee:
          data.invite.tableheader_invitee || state.invite.tableheader_invitee,
        placeholder_invite_family:
          data.invite.placeholder_invite_family ||
          state.invite.placeholder_invite_family,
          label_remove: data.invite.label_remove || state.invite.label_remove,
          label_first_name: data.invite.label_first_name || state.invite.label_first_name,
          label_last_name: data.invite.label_last_name || state.invite.label_last_name,
          label_relationship: data.invite.label_relationship || state.invite.label_relationship,
          label_select_one: data.invite.label_select_one || state.invite.label_select_one,
          label_dob: data.invite.label_dob || state.invite.label_dob,
          label_related_person: data.invite.label_related_person || state.invite.label_related_person,
          prompt_valid_number: data.invite.prompt_valid_number || state.invite.prompt_valid_number,
          prompt_valid_dob: data.invite.prompt_valid_dob || state.invite.prompt_valid_dob,
          label_success: data.invite.label_success || state.invite.label_success,
          label_success_subtitle: data.invite.label_success_subtitle || state.invite.label_success_subtitle,
          label_error: data.invite.label_error || state.invite.label_error,
          label_error_subtitle: data.invite.label_error_subtitle || state.invite.label_error_subtitle,
          relation_significant_other: data.invite.relation_significant_other || state.invite.relation_significant_other,
          relation_spouse: data.invite.relation_spouse || state.invite.relation_spouse,
          relation_former_spouse: data.invite.relation_former_spouse || state.invite.relation_former_spouse,
          relation_sibling: data.invite.relation_sibling || state.invite.relation_sibling,
          relation_dependent: data.invite.relation_dependent || state.invite.relation_dependent,
          relation_extended_family: data.invite.relation_extended_family || state.invite.relation_extended_family,
          relation_parent: data.invite.relation_parent || state.invite.relation_parent,
          relation_neighbor: data.invite.relation_neighbor || state.invite.relation_neighbor,
          relation_roommate: data.invite.relation_roommate || state.invite.relation_roommate,
          relation_unrelated_friend: data.invite.relation_unrelated_friend || state.invite.relation_unrelated_friend,
          relation_rep_payee: data.invite.relation_rep_payee || state.invite.relation_rep_payee,
          relation_guardian: data.invite.relation_guardian || state.invite.relation_guardian,
          relation_conservator: data.invite.relation_conservator || state.invite.relation_conservator,
          relation_attorney: data.invite.relation_attorney || state.invite.relation_attorney,
          relation_ward_of_court: data.invite.relation_ward_of_court || state.invite.relation_ward_of_court,
          relation_other: data.invite.relation_other || state.invite.relation_other,
          header_name: data.invite.header_name || state.invite.header_name,
          header_status: data.invite.header_status || state.invite.header_status,
          header_relationship: data.invite.header_relationship || state.invite.header_relationship,
          status_invited: data.invite.status_invited || state.invite.status_invited,
          status_added: data.invite.status_added || state.invite.status_added,
      }
    }));
  }, [data]);

  const sidebarInit = {
    profile: "Profile",
    consents: "Consents",
    notifications: "Notifications",
    badges: "Badges",
    invite: "Related Persons"
  };
  const { data: sidebarData, loading: sidebarLoading } = useStrapi({
    cms: `navigation-profile`
  });

  const [sidebar, setSidebar] = useState(sidebarInit);
  useEffect(() => {
    if (sidebarLoading) {
      setSidebar(sidebarInit);
      return;
    }
    setSidebar((state) => ({
      profile: sidebarData.items.profile || state.profile,
      consents: sidebarData.items.consents || state.consents,
      notifications: sidebarData.items.notifications || state.notifications,
      badges: sidebarData.items.badges || state.badges,
      invite: sidebarData.items.invite || state.invite
    }));
  }, [sidebarData]);

    const badgesInit = {
      accepted_referral: {
        id: 1,
        text: "Accepted a Referral",
        tooltipText:
          "Providers can refer you for services. Learn more about accepting referrals.\n",
        tooltipRedirect: "/360dashboard/service-requests",
        badgeType: "accepted-referral",
      },
      building_care_team: {
        id: 1,
        text: "Building a Care Team",
        tooltipText:
          "Add a Provider to your Care Team to earn this badge. Learn how.\n",
        tooltipRedirect: "/service-directory",
        badgeType: "care-team",
      },
      check_in: {
        id: 1,
        text: "Check-In",
        tooltipText:
          "Check-in to earn to this badge and learn more about your wellbeing.\n",
        tooltipRedirect: "/checkin",
        badgeType: "check-in",
      },
      completed_profile: {
        id: 1,
        text: "Completed Profile",
        tooltipText:
          "Complete your full profile to earn this badge. Get started now.\n",
        tooltipRedirect: "/complete-profile",
        badgeType: "completed-profile",
      },
      invite_friend: {
        id: 1,
        text: "Invite a Friend",
        tooltipText:
          "Invite a friend by going to the 'Invite' tab on your account page.\n",
        tooltipRedirect: "/account/related-persons",
        badgeType: "invite-a-friend",
      },
      needs_request: {
        id: 1,
        text: "Needs Request",
        tooltipText:
          "Reach out to a provider and submit a needs request. Learn how.\n",
        tooltipRedirect: "/service-directory",
        badgeType: "needs-request",
      },
      new_member: {
        id: 1,
        text: "New Member",
        tooltipText:
          "This badge is earned by completing the welcome introduction after signing up.\n",
        tooltipRedirect: "/account",
        badgeType: "new-member",
      },
      referral_feedback: {
        id: 1,
        text: "Completed Referral Feedback",
        tooltipText: "Give feedback about your experiences to earn this badge.",
        tooltipRedirect: "/feed",
        badgeType: "referral-feedback",
      },
      btn_get_started: "Get Started",
      btn_loading: "Loading"
    };
    
    const { data: badgeData, loading: badgeDataLoading } = useStrapi({
      cms: `list-badge`
    });

    const [ badges, setBadges] = useState(sidebarInit);
    useEffect(() => {
      if (badgeDataLoading) {
        setBadges(badgesInit);
        return;
      }
      setBadges((state) => ({
        accepted_referral: badgeData.accepted_referral || state.accepted_referral,
        building_care_team: badgeData.building_care_team || state.building_care_team,
        check_in: badgeData.check_in || state.text.check_in,
        completed_profile: badgeData.completed_profile || state.completed_profile,
        invite_friend: badgeData.invite_friend || state.invite_friend,
        needs_request: badgeData.needs_request || state.needs_request,
        new_member: badgeData.new_member || state.new_member,
        referral_feedback: badgeData.referral_feedback || state.referral_feedback,
        btn_get_started: badgeData.btn_get_started || state.btn_get_started,
        btn_loading: badgeData.btn_loading || state.btn_loading
    }))
  }, [badgeData]);

  return { account, sidebar, badges };
};
