import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useRemoveCMS = () => {
  const reasonsInit = {
    completed: "Completed needed services",
    dissatisfied: "Dissatisfied with services",
    moved_out: "Moved out of provider area",
    cost: "Cost of services too high",
    prefer_not: "Prefer not to disclose",
    other: "Other",
    remove_default: "Select a reason for removing",
    service_disconnected: "Service was disconnected",
    no_current_housing: "No current housing",
    member_deactivated: "Member deactivated their account",
  };
  const { data: reasonsData, loading: reasonsLoading } = useStrapi({
    cms: `list-remove-reason`
  });

  const [reasons, setReasons] = useState(reasonsInit);

  useEffect(() => {
    if (reasonsLoading) {
      setReasons(reasonsInit);
      return;
    }
    setReasons((state) => ({
      completed: reasonsData.items.completed || state.completed,
      dissatisfied: reasonsData.items.dissatisfied || state.dissatisfied,
      moved_out: reasonsData.items.moved_out || state.moved_out,
      cost: reasonsData.items.cost || state.cost,
      prefer_not: reasonsData.items.prefer_not || state.prefer_not,
      other: reasonsData.items.other || state.other,
      remove_default: reasonsData.items.remove_default || state.remove_default,
      service_disconnected: reasonsData.items.service_disconnected || state.service_disconnected,
      no_current_housing: reasonsData.items.no_current_housing || state.no_current_housing,
      member_deactivated: reasonsData.items.member_disconnected || state.member_disconnected
    }));
  }, [reasonsData]);

  const labelInit = {
    heading: "Remove from Care Team",
    btn_remove: "Remove",
    btn_remove_loading: "Removing from care team...",
    btn_cancel: "Cancel",
    label_remove_reason: "Select a reason for removing...",
    label_provider_reason: "Provide a reason here",
    error_provider_text: "Reason must be between 3 and 150 characters",
    text_are_you_sure:
      "Are you sure you want to remove [provider] from your Care Team?",
    text_include_active:
      "If you remove [provider] from your Care Team, any active referrals to this provider will be closed."
  };
  const { data: labelData, loading: labelLoading } = useStrapi({
    cms: `modal-remove-care-team`
  });

  const [label, setLabel] = useState(labelInit);

  useEffect(() => {
    if (labelLoading) {
      setLabel(labelInit);
      return;
    }
    setLabel((state) => ({
      heading: labelData.heading || state.heading,
      btn_remove: labelData.btn_remove || state.btn_remove,
      btn_remove_loading:
        labelData.btn_remove_loading || state.btn_remove_loading,
      btn_cancel: labelData.btn_cancel || state.btn_cancel,
      label_remove_reason:
        labelData.label_remove_reason || state.label_remove_reason,
      label_provider_reason:
        labelData.label_provider_reason || state.label_provider_reason,
      error_provider_text:
        labelData.error_provider_text || state.error_provider_text,
      text_are_you_sure: labelData.text_are_you_sure || state.text_are_you_sure,
      text_include_active:
        labelData.text_include_active || state.text_include_active
    }));
  }, [labelData]);

  return { label, reasons, cms: label };
};
