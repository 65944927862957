import React, { useState, useEffect, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";

export const OverviewContext = React.createContext([]);

export const OverviewProvider = ({ children }) => {
  const { user, updateUser } = useContext(kit.UserContext);
  const [domains, setDomains] = useState(
    user?.domains ? [...user?.domains] : []
  );

  useEffect(() => {
    socket.emit("domains:subscribe", { id: user.id }, (data) => {
      updateDomains(data.domains);
    });
  }, []);

  const getLatestDomains = () => {
    socket.emit("domains:latest", { id: user.id }, (data) => {
      setDomains(data.domains);
    });
  };
  const updateDomainPriority = (domains) => {
    socket.emit("domains:update", { id: user.id, isProvider: false, domains: domains });
  };

  const updateDomains = (updatedDomains) => {
    // Preserves activity feed in user context as well as localstorage
    setDomains(updatedDomains);
    updateUser({ ...user, domains: updatedDomains });
  };


  return (
    <OverviewContext.Provider value={{ domains, updateDomainPriority, getLatestDomains }}>
      {children}
    </OverviewContext.Provider>
  );
}

export const OverviewConsumer = OverviewContext.Consumer;