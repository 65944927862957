import { useEffect, useState } from "react";
import { useStrapi } from "../hooks/useStrapi";

export const useValidationCMS = () => {
  const validationInit = {
    required_uppercase: "An uppercase letter",
    required_lowercase: "A lowercase letter",
    required_valid_symbol: "One valid symbol: #?!@$%^&*-",
    required_no_spaces_special: "No spaces or other special chars",
    required_num_characters: "10-31 characters",
    required_number: "A Number",
  };
  const { data, loading } = useStrapi({
    cms: `validation`,
  });

  const [validations, setValidations] = useState(validationInit);

  useEffect(() => {
    if (loading) {
      setValidations(validationInit);
      return;
    }
    setValidations((state) => ({
      required_uppercase: data.required_uppercase || state.required_uppercase,
      required_lowercase: data.required_lowercase || state.required_lowercase,
      required_valid_symbol:
        data.required_valid_symbol || state.required_valid_symbol,
      required_no_spaces_special:
        data.required_no_spaces_special || state.required_no_spaces_special,
      required_num_characters:
        data.required_num_characters || state.required_num_characters,
      required_number: data.required_number || state.required_number,
    }));
  }, [data]);

  return { validations };
};
