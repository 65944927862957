import React, { useContext, useEffect, useState } from "react";
import S from "../confirm-enrollment.module.scss";
import kit from "ich-ui-kit";
import { Button, InlineLoading, Modal } from "carbon-components-react";
import RemoveFromCareTeam from "../../../components/ProviderCardsRemoveCareTeamModal";
import { LocaleContext } from "../../../store/LocaleContext";

const ConfirmEnrollment0 = ({ page, setPage, user, clearUser, cms }) => {
  const { locale } = useContext(LocaleContext);
  const { apiService } = useContext(kit.ApiContext);
  const [careTeam, setCareTeam] = useState(null);
  const [loadingCareTeam, setLoadingCareTeam] = useState(true);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [deactivateModalText, setDeactivateModalText] = useState(cms.modal_deactivate_warning);
  const [openRemoveFromCareTeam, setOpenRemoveFromCareTeam] = useState(false);
  const [careTeamRemoved, setCareTeamRemoved] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    setDeactivateModalText(cms.modal_deactivate_warning)
  }, [cms])

  useEffect(() => {
    const getCareTeam = async () => {
      try {
        setLoadingCareTeam(true);
        const data = await apiService.get("/careteam");
        if (data && data?.cTeam && data?.cTeam.length > 0) {
          setCareTeam(data.cTeam[0]);
        }
      } catch (err) {
        console.log(err);
      }
      setLoadingCareTeam(false);
    };
    getCareTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (careTeamRemoved) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careTeamRemoved]);

  useEffect(() => {
    try {
      const getLongitudinalRecord = async () => {
        // setLoading(true);
        const response = await apiService.get(`/eventhistory/${user.id}?locale=${locale?.code ?? 'en'}`);
        if (response.ok) {
          const rows = response.eventhistory.map((r) => ({
            ...r,
            createdAt: new Date(r.createdAt).toLocaleString(), // converting from UTC to local time
          }));
          const enrollmentInfo = rows.filter(
            (r) =>
              r.eventName === "Account Management" &&
              r.eventSummary.includes("facilitated enrollment")
          );
          if (enrollmentInfo) {
            setProviderName(enrollmentInfo[0].relatedObject);
            setOrgName(enrollmentInfo[0].eventSummary.split(" with ")[1]);
          }
          return;
        } else {
          // setLoading(false);
          throw new Error(response.message);
        }
      };
      getLongitudinalRecord();
    } catch (e) {
      console.error(e);
    }
  }, [locale]);

  const deactivateAccount = async () => {
    try {
      setDeactivateLoading(true);
      const data = await apiService.post("/account/deactivate");
      if (data.ok) {
        setDeactivateModalText(cms.modal_deactivate);
        window.setTimeout(() => {
          clearUser();
          window.location.href = "/login";
        }, 5000);
      } else if (!data.ok && data.message.includes("already deactivated")) {
        setDeactivateModalText(cms.modal_deactivate);
        window.setTimeout(() => {
          clearUser();
          window.location.href = "/login";
        }, 5000);
      } else {
        setDeactivateLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeactivateLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={deactivateOpen}
        danger
        onRequestSubmit={deactivateAccount}
        onRequestClose={() => {
          setDeactivateOpen(false);
        }}
        primaryButtonDisabled={deactivateLoading}
        modalHeading={cms.modal_are_you_sure}
        modalLabel=""
        primaryButtonText={
          deactivateLoading ? (
            <InlineLoading
              description={`${cms.modal_btn_deactivating}...`}
              style={{ minHeight: "auto" }}
            />
          ) : (
            cms.modal_btn_deactivate
          )
        }
        secondaryButtonText={cms.btn_cancel}
      >
        <p>{deactivateModalText}</p>
      </Modal>
      <RemoveFromCareTeam
        open={openRemoveFromCareTeam}
        setOpen={setOpenRemoveFromCareTeam}
        selectedProvider={careTeam}
        setCareTeamRemoved={setCareTeamRemoved}
      />
      <div className={S.content}>
        <div className={S.header0}>{cms.title_confirm_enrollment}</div>
        <div className={S.paragraph}>
          {cms.desc_account_created
            .replace("[provider_name]", providerName)
            .replace("[org_name]", orgName)
            .replace("[careteam_name]", careTeam?.name || "")}
          <div className={S.paragraph}></div>
          {cms.desc_continue}
        </div>
        <div>
          <Button
            className={S.buttonPrimary}
            kind="primary"
            onClick={() => {
              setPage(1);
            }}
          >
            {cms.btn_continue}
          </Button>
        </div>
        <div className={S.paragraph}>
          <strong>{cms.title_cancel_enrollment}</strong>
          <p>
            {cms.desc_cancel_enrollment}
          </p>
          <Button
            className={S.buttonTertiary}
            kind="ghost"
            onClick={() => setDeactivateOpen(true)}
          >
            {cms.btn_cancel}
          </Button>
        </div>
      </div>
      {/* <div className={S.bottom}>
        <div className={S.paragraph}>
          <strong>Continue with Enrollment</strong>
          {careTeam ? (
            <>
              <p>
                Would you like to keep {careTeam?.name} on your Care Team? You
                can remove providers from your Care Team at any time.
              </p>
              <div>
                <Button
                  className={S.buttonPrimary}
                  kind="primary"
                  onClick={() => {
                    console.log("ths is being clicked");
                    setPage(1);
                    console.log(page);
                  }}
                >
                  Continue
                </Button>
                <Button
                  className={S.buttonTertiary}
                  kind="tertiary"
                  onClick={() => setOpenRemoveFromCareTeam(true)}
                >
                  Remove Care Team & Continue
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>
                If you choose to continue, you’ll get your own MyWayfinder
                Dashboard to keep track of how you are doing and make it easier
                to connect with your service providers.
              </p>
              <div>
                <Button
                  className={S.buttonPrimary}
                  kind="primary"
                  onClick={() => {
                    console.log("ths is being clicked");
                    setPage(1);
                    console.log(page);
                  }}
                >
                  Continue
                </Button>
              </div>
            </>
          )}
        </div>
      </div> */}
    </>
  );
};

export default ConfirmEnrollment0;
