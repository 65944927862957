import React, { useEffect, useState, useContext } from "react";
import kit from "ich-ui-kit";
import * as S from "./careTeam.styled";
import styled from "styled-components";
import { Modal } from "carbon-components-react";
import { createPortal } from "react-dom";

import { useRemoveCMS } from "./cms";
import { shouldAutoComplete } from "../../utils";

const mount = document.getElementById("modal-root");
const RemoveFromCareTeam = ({
  open,
  setOpen,
  selectedProvider,
  setCareTeamRemoved
}) => {
  const { label, reasons } = useRemoveCMS();
  const { apiService } = useContext(kit.ApiContext);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [removeReason, setRemoveReason] = useState("");
  const [removeReasonErr, setRemoveReasonErr] = useState(false);
  const [removeReasonOther, setRemoveReasonOther] = useState("");

  const removeReasonItems = [
    {
      text: reasons.completed,
      key: "Completed needed services"
    },
    {
      text: reasons.dissatisfied,
      key: "Dissatisfied with services"
    },
    {
      text: reasons.moved_out,
      key: "Moved out of provider area"
    },
    {
      text: reasons.cost,
      key: "Cost of services too high"
    },
    {
      text: reasons.prefer_not,
      key: "Prefer not to disclose"
    },
    {
      text: reasons.other,
      key: "Other"
    }
  ];

  const broadcastEventToResourcePage = (event) => {
    localStorage.setItem("newCareTeam", JSON.stringify(event));
  };

  const removeProvider = async () => {
    if (removeReason?.key === "" || removeReason?.key === "Select One...") {
      setRemoveReasonErr(true);
      return;
    }
    if (removeReasonErr) {
      setRemoveReasonErr(false);
    }
    try {
      setRemoveLoading(true);

      const reason = {
        reason: removeReason?.key,
        other: false
      };

      if (removeReason.key === "Other" && removeReasonOther.length >= 3) {
        reason.reason = removeReasonOther;
        reason.other = true;
      }

      const data = await apiService.post("/careteam/removal", {
        serviceAtLocationId: selectedProvider?.serviceAtLocationId,
        serviceId: selectedProvider?.serviceId,
        locationId: selectedProvider?.locationId,
        orgSource: selectedProvider?.orgSource,
        reason: reason?.reason
      });

      if (data.ok) {
        setCareTeamRemoved(selectedProvider);
        broadcastEventToResourcePage({
          provider: selectedProvider,
          eventType: "remove"
        });
        removeModalClose();
      } else {
        console.error("Issue removing from care team");
      }
    } catch (err) {
      console.log(err);
    }
    setRemoveReasonOther("");
    setRemoveReason("");
    setRemoveLoading(false);
  };

  const removeModalClose = () => {
    setOpen(false);
    setRemoveReason("");
    setRemoveReasonOther("");
  };

  const modalSubmitDisabled = () => {
    if (removeLoading || removeReason === "") {
      return true;
    } else if (
      removeReason === "Other" &&
      (removeReasonOther.length < 3 || removeReasonOther.length > 150)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return createPortal(
    <StyledModal
      open={open}
      danger
      size="sm"
      modalHeading={label.heading}
      onRequestSubmit={removeProvider}
      onRequestClose={removeModalClose}
      primaryButtonDisabled={modalSubmitDisabled()}
      primaryButtonText={
        removeLoading ? label.btn_remove_loading : label.btn_remove
      }
      secondaryButtonText={label.btn_cancel}
    >
      <div style={{ minHeight: "17rem" }}>
        <S.StyledDropdown
          id={`remove-careteam-modal-${selectedProvider?.serviceAtLocationId}`}
          data-id={`remove-careteam-modal-${selectedProvider?.serviceAtLocationId}`}
          data-cy={`remove-careteam-modal-${selectedProvider?.serviceAtLocationId}`}
          ariaLabel="Dropdown"
          items={removeReasonItems}
          itemToString={(item) => (item ? item.text : "")}
          label={reasons.remove_default}
          onChange={(e) => {
            setRemoveReason(e.selectedItem);
          }}
          selectedItem={removeReason}
          invalid={removeReasonErr}
        />
        {removeReason.key == "Other" && (
          <>
            <br />
            <S.StyledTextInput
              onChange={(e) => setRemoveReasonOther(e.target.value)}
              placeholder={label.label_provider_reason}
              maxLength="150"
              invalid={removeReasonOther.length < 3}
              invalidText={label.error_provider_text}
              autoComplete={shouldAutoComplete}
            />
          </>
        )}
        <br />
        <p
          dangerouslySetInnerHTML={{
            __html: label.text_are_you_sure?.replace(
              "[provider]",
              `<strong>${selectedProvider?.name}</strong>`
            )
          }}
        />
        <br />
        <p
          dangerouslySetInnerHTML={{
            __html: label.text_include_active?.replace(
              "[provider]",
              `<strong>${selectedProvider?.name}</strong>`
            )
          }}
        />
      </div>
    </StyledModal>,
    mount
  );
};

export default RemoveFromCareTeam;

const StyledModal = styled(Modal)`
  .bx--modal-container {
    max-width: 600px;
    @media screen and (min-width: 1024px) {
      margin-left: 15rem;
    }
  }
`;
