import React, { useState, useLayoutEffect, useContext } from "react";
import { backend } from "../axios/backend.instance";
import kit from "ich-ui-kit";

import { handleCommunicationPreference } from "../utils";

export const UserProfileContext = React.createContext({});

export const UserProfileProvider = ({ children }) => {
  const { user } = useContext(kit.UserContext);
  const [userProfile, setUserProfile] = useState({
    personal: {},
    contact: {},
    eContact: {},
  });

  useLayoutEffect(() => {
    const getUserData = async () => {
      const res = await backend.get("/account/user/data", {
        headers: {
          Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
        },
      });
      const { user: profile } = res;
      if (profile) {
        setUserProfile({
          personal: {
            firstName: profile.firstName ?? '',
            lastName: profile.lastName ?? '',
            ssn: profile.ssn ?? '',
            dob: profile.dateOfBirth ?? '',
            language: profile.language ?? '',
            age: profile.age ?? '',
            nickName: profile.aliasName ?? '',
            hhs: "",
            sex: profile.gender ?? '',
            gender: profile.genderIdentity ?? '',
            race: profile.race ?? '',
            ethnicity: profile.ethnicity ?? '',
            hispanicOrigin: profile.hispanicOrigin ?? '',
            maritalStatus: profile.maritalStatus ?? '',
            homeOccupancy: profile.homeOccupancy ?? '',
            homeOccupancyChildren: profile.homeOccupancyChildren ?? '',
            homeOccupancyAdults: profile.homeOccupancyAdults ?? '',
          },
          contact: {
            phone: profile.phone ?? '',
            email: profile.email ?? '',
            line1: profile?.addressList?.address?.address1 ?? "",
            line2: profile?.addressList?.address?.address2 ?? "",
            city: profile?.addressList?.address?.city ?? "",
            state: profile?.addressList?.address?.state ?? "",
            zipCode: profile?.addressList?.address?.postalCode ?? "",
            preferredContactMethod: profile.communicationpreference
              ? handleCommunicationPreference(profile.communicationpreference)
              : "email",
            housingSituation: profile.housingSituation ?? "",
          },
          eContact: {
            fname: profile.emergencyContactInfo.firstName ?? '',
            lname: profile.emergencyContactInfo.lastName ?? '',
            rel: profile.emergencyContactInfo.relationship ?? '',
            email: profile.emergencyContactInfo.email ?? '',
            phone: profile.emergencyContactInfo.phoneÍ ?? '',
          },
        });
      }
    };

    // Get Profile data if user is logged in
    if (
      Object.keys(user).filter((key) => key !== "notifications").length !== 0
    ) {
      getUserData();
    }
  }, []);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      <>{children}</>
    </UserProfileContext.Provider>
  );
};

export const UserProfileConsumer = UserProfileContext.Consumer;
