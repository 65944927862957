import React, { useState, useContext } from "react";
import kit from "ich-ui-kit";
import socket from "../socket";

export const FeedbackContext = React.createContext([]);

export const FeedbackProvider = ({ children }) => {
  const { user } = useContext(kit.UserContext);
  const [feedback, setFeedback] = useState();


  const getFeedback = (referralId) => {
    socket.emit("feedback:response", { id: user.id, referralId: referralId, isProvider: false }, (data) => {
      setFeedback(data.feedback);
      return data.feedback;
    });
  };
  const addFeedback = (feedbackData, referralId, locationId, serviceId, orgId, serviceNPS, wayfinderNPS) => {
    socket.emit("feedback:update", { id: user.id, referralId: referralId, locationId: locationId, feedback: feedbackData, serviceId: serviceId, orgId: orgId, serviceNPS, wayfinderNPS});
  };

  return (
    <FeedbackContext.Provider value={{ feedback, addFeedback, getFeedback }}>
      {children}
    </FeedbackContext.Provider>
  );
}

export const FeedbackConsumer = FeedbackContext.Consumer;