// cms-endpoint: 'page-login'
export default function validate(values, cms={}) {
  const errors = {
    email: "",
    confirmEmail: "",
    password: ["lower", "upper", "count", "number", "symbol", "nospace"],
    confirmPassword: "",
    dob: ""
  };

  const checkDate = (date) => {
    if (date && date.length === 10) {
      const today = new Date();
      const dob = new Date(date);
      let age = today.getFullYear() - dob.getFullYear();
      const month = today.getMonth() - dob.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      if (age >= 13) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  if (values.email) {
    if (values.email === "") {
      errors.email = cms.error_required_email || "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = cms.error_valid_email || "Please enter a valid email address";
    }
  }
  if (values.dob) {
    if (!/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(values.dob)) {
      errors.dob = cms.error_valid_date || "Must be valid date";
    } else if (checkDate(values.dob)) {
      errors.dob = cms.error_dob ||  "Must be at least 13 years of age"
    }
  }
  if (values.password) {
    if (/.*?[A-Z]/.test(values.password)) {
      errors.password = errors.password.filter((e) => e !== "upper");
    }
    if (/.*?[a-z]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "lower");
    }
    if (/.*?[0-9]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "number");
    }
    if (/.*?[#?!@$%^&*-]/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "symbol");
    }
    if (/.{10,}/.test(values.password) === true) {
      errors.password = errors.password.filter((e) => e !== "count");
    }
    if (
      /\s/.test(values.password) === false &&
      /.*?[[{}\\;:'"/.>,<_=+`~|\]]/.test(values.password) === false
    ) {
      errors.password = errors.password.filter((e) => e !== "nospace");
    }
    // if (/.*?[{}\\;:'"/.>,<_=+`~|]/.test(values.password) === false) {
    //   errors.password = errors.password.filter((e) => e !== "nospace");
    // }
  }

  if (values.confirmPassword) {
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (/.*?[A-Z]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (/.*?[a-z]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (/.*?[0-9]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (/.*?[#?!@$%^&*-]/.test(values.confirmPassword) === false) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (/.{10,}/.test(values.confirmPassword) === false) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
    if (
      /\s/.test(values.password) === false &&
      /.*?[[{}\\;:'"/.>,<_=+`~|\]]/.test(values.password) === true
    ) {
      errors.confirmPassword = cms.error_confirm_password || "Passwords must match and meet requirements";
    }
  }
  return errors;
}
