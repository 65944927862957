import axios from "axios";

export const strapi = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_ENDPOINT,
  headers: {
    "Content-Type": "application/json"
  }
});

strapi.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    return 500;
  }
);
