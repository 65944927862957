import React, { useEffect, useState } from "react";

export const GeolocationContext = React.createContext({});

export const GeolocationProvider = ({ children }) => {
  const [geolocation, setGeolocation] = useState({ lat: null, long: null});

  useEffect(() => {
    checkPosition()
  }, []);

  const checkPosition = () => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {

      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          setGeolocation({ lat, long });
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error?.message);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <GeolocationContext.Provider value={{ geolocation }}>
      <>{children}</>
    </GeolocationContext.Provider>
  );
};

export const GeolocationConsumer = GeolocationContext.Consumer;
