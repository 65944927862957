import {
  ArrowRight16,
  CaretDown16,
  Menu24,
  Notification24,
  User24,
} from "@carbon/icons-react";
import {
  Content,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderName,
  HeaderPanel,
  Loading,
  Modal,
  Switcher,
  SwitcherDivider,
  Tooltip,
} from "carbon-components-react";
import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { backend } from "../../axios/backend.instance";
import { useComponentVisible } from "../../hooks/useComponentVisible";
import { useDimension } from "../../hooks/useDimension";
import AnonymousFooter from "../../pages/anonymous/components/anonymousFooter/footer";
import { ActivityFeedContext } from "../../store/ActivityFeedContext";
import { NavContext } from "../../store/NavContext";
import LanguageDropdown from "../LanguageDropdown";
import PasswordWarningModal from "../PasswordWarningModal";
import { useNavbarCMS } from "./cms";
import DropdownMenu from "./components/dropdown-menu";
import {
  CustomHeaderPanel,
  CustomSwitcherItem,
  MobileNav,
  NewNotification,
  StyledHeaderMenuItem,
  UIShellContainer,
} from "./components/static";
import * as S from "./uishell.styled";
import { LocaleContext } from "../../store/LocaleContext";
import SS from "./uishell.module.scss";
import { handleLogoutUtil } from "../../utils";

const showPasswordExpiry = false;

const UIShell = ({
  loadingSession,
  exceededRequest,
  setLoadingLogout,
  channel,
  children,
}) => {
  const { locale, setLocale } = useContext(LocaleContext);
  // Hooks
  const { navbar, domain, app, dashnav } = useNavbarCMS();
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);
  const { pathname } = useLocation();
  let location = useLocation();
  const history = useHistory();
  // Contexts
  const { numberUnread, getTotalUnread } = useContext(ActivityFeedContext);
  const {
    mobileNavOpen,
    setMobileNavOpen,
    profileImageChange,
    userNameChange,
  } = useContext(NavContext);
  const { user, clearUser } = useContext(kit.UserContext);
  const { apiService } = useContext(kit.ApiContext);
  // States
  const [is360DashboardMenuHovered, setIs360DashboardMenuHovered] =
    useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [userName, setUserName] = useState();
  const [switcher, setSwitcher] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);
  const [passwordExpiry, setPasswordExpiry] = useState("");
  const [profileTooltip, setProfileTooltip] = useState(false);
  // Responsiveness
  let mobile = width <= 1055;
  const { windowSize } = useDimension();
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (windowSize.width >= 1024) {
      setMobileNavOpen(false);
      setSwitcher(false);
    }
  }, [windowSize]);

  // TODO revisit rate limiting
  useEffect(() => {
    if (exceededRequest) {
      setOpenModal(true);
    }
  }, [exceededRequest]);

  /*   
    // Updating Locale for Watson Assistant
    useEffect(()=>{
      window.WebChatInstance?.updateLocale(locale.code);
    }, [locale]) 
  */

  useEffect(() => {
    const getProfileImage = async () => {
      try {
        const data = await apiService.get(`/profileimages`);
        if (data.ok) {
          setProfileImage(data.image);
        } else {
          console.log(data.message);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (!loadingSession && loggedIn) {
      getProfileImage();
    }
  }, [apiService, loggedIn, loadingSession]);

  useEffect(() => {
    const updateProfileImage = () => {
      profileImageChange.eventType === "add"
        ? setProfileImage(profileImageChange.img)
        : setProfileImage();
    };
    updateProfileImage();
  }, [profileImageChange]);

  useEffect(() => {
    const updateUserName = () => {
      userNameChange.eventType === "update"
        ? setUserName(userNameChange.name)
        : setUserName();
    };
    updateUserName();
  }, [userNameChange]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(user).filter((key) => key !== "notifications").length) {
      if (typeof apiService.adjustHeaders === "function") {
        apiService.adjustHeaders();
      }
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {
    //forces pages to start at top
    document.getElementById("content").scrollTo(0, 0);
  }, [pathname]);

  const logout = async () => {
    handleLogoutUtil({ channel, setLoadingLogout, clearUser, history, shouldSetLoadingLogoutToFalse: false })
    try {
      const res = await backend.post(
        "/iam/logout",
        {
          event: "logout",
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
          },
        }
      );

      if (res) {
        setTimeout(() => {
          setLoadingLogout(false);
        }, "2000");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleSwitcher = (url) => {
    history.push(url);
    closeComponentClick();
  };

  const closeComponentClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  window.onstorage = (e) => {
    if (e.key === "passwordChanged" || e.key === "logout") {
      logout();
    }
  };

  const broadcastLogOut = () => {
    localStorage.setItem("logout", JSON.stringify(true));
  };

  useEffect(() => {
    if (user?.passwordExpirationTimestamp) {
      // get difference in days until expiry
      const expiryCountdown = Math.round(
        (new Date(user?.passwordExpirationTimestamp) - new Date()) / 86400000
      );
      setPasswordExpiry(expiryCountdown);
    }
  }, [user]);

  return (
    <UIShellContainer
      id="UIShell"
      style={{ overflowY: location.pathname === "/referral" ? "clip" : "auto" }}
    >
      {exceededRequest && (
        <Modal
          passiveModal
          onClick={closeModal}
          open={openModal}
          modalHeading="Requests exceeded"
        >
          <p style={{ marginBottom: "1rem" }}>
            You have exceeded the allowed requests. Please wait!
          </p>
        </Modal>
      )}
      <S.Header aria-label="header">
        <span className={SS.headerNameWrapper}>
        <HeaderName
          prefix=""
          onClick={() => history.push("/")}
          style={{ cursor: "pointer", color: "#ffffff", fontWeight: 700 }}
        >
          {app || "MyWayfinder"}
        </HeaderName>
        </span>
        <S.HeaderNavigation aria-label="navigation">
          <StyledHeaderMenuItem
            onClick={() => history.push("/")}
            aria-label={navbar.home}
            data-id="nav-home-btn"
          >
            {navbar.home}
          </StyledHeaderMenuItem>
          <StyledHeaderMenuItem
            aria-label={navbar.service_directory}
            // onMouseEnter={() => setIsResourcesMenuHovered(true)}
            // onMouseLeave={() => setIsResourcesMenuHovered(false)}
            style={{ paddingRight: "0.6rem" }}
            data-id="nav-service-directory-dropdown"
          >
            <span
              style={{ display: "flex" }}
              onClick={() => {
                history.push("/service-directory");
                // setIsResourcesMenuHovered(false);
              }}
            >
              {navbar.service_directory}
              {/* <CaretDown16 /> */}
            </span>
            {/* {isResourcesMenuHovered && (
              <DropdownMenu
                cmsData={domain}
                setIsResourcesMenuHovered={setIsResourcesMenuHovered}
              />
            )} */}
          </StyledHeaderMenuItem>
          {!loadingSession && loggedIn ? (
            <StyledHeaderMenuItem
              aria-label={navbar.dashboard}
              onMouseEnter={() => setIs360DashboardMenuHovered(true)}
              onMouseLeave={() => setIs360DashboardMenuHovered(false)}
              style={{ paddingRight: "0.6rem" }}
              data-id="nav-dashboard-dropdown"
            >
              <span
                style={{ display: "flex" }}
                onClick={() => history.push("/360dashboard")}
              >
                {navbar.dashboard}
                <CaretDown16 />
              </span>
              {is360DashboardMenuHovered && (
                <DropdownMenu
                  cmsData={dashnav}
                  setIs360DashboardMenuHovered={setIs360DashboardMenuHovered}
                />
              )}
            </StyledHeaderMenuItem>
          ) : null}
          {!loadingSession && loggedIn ? (
            <StyledHeaderMenuItem
              onClick={() => history.push("/feed")}
              aria-label={navbar.activity_feed}
              data-id="nav-feed-btn"
            >
              {navbar.activity_feed}
              {numberUnread && getTotalUnread() > 0 && (
                <NewNotification quantity={getTotalUnread()} />
              )}
            </StyledHeaderMenuItem>
          ) : null}
        </S.HeaderNavigation>
        <HeaderGlobalBar>
          {!loadingSession && loggedIn && mobile === true ? (
            <HeaderGlobalAction
              aria-label={navbar.activity_feed}
              onClick={() => history.push("/feed")}
              data-id="nav-feed-btn"
            >
              <Notification24
                style={
                  numberUnread && getTotalUnread() > 0 ? { fill: "red" } : {}
                }
              />
              <br />
              <span> </span>
            </HeaderGlobalAction>
          ) : null}
          {!mobile && (
            <S.FlexAlignCenter>
              <div style={{ marginRight: !loggedIn ? "1rem" : "0.5rem" }}>
                <LanguageDropdown />
              </div>
              {loggedIn && (
                <StyledHeaderMenuItem
                  data-id="nav-logout-btn"
                  onClick={() => {
                    logout();
                    // broadcastLogOut();
                  }}
                  aria-label={navbar.logout}
                  style={{
                    marginRight: "0.5rem",
                  }}
                >
                  {navbar.logout}
                </StyledHeaderMenuItem>
              )}
            </S.FlexAlignCenter>
          )}
          {!loadingSession && loggedIn && !mobile ? (
            <>
              <HeaderGlobalAction
                data-id="nav-account-btn"
                className="user-nav-button"
                aria-label={navbar.profile}
                onClick={() => {
                  setSwitcher(!switcher);
                  closeComponentClick();
                }}
                onMouseEnter={() => setProfileTooltip(true)}
                onMouseLeave={() => setProfileTooltip(false)}
              >
                <User24 />
                {showPasswordExpiry && (
                  <Tooltip
                    showIcon={false}
                    open={profileTooltip}
                    direction="bottom"
                    align="end"
                  >
                    <p className=".bx--tooltip__body">
                      <strong>Profile</strong>
                    </p>
                    {(passwordExpiry || passwordExpiry === 0) &&
                      (passwordExpiry === 1 ? (
                        <p>Password expires {passwordExpiry} day</p>
                      ) : (
                        <p>Password expires {passwordExpiry} days</p>
                      ))}
                  </Tooltip>
                )}
              </HeaderGlobalAction>
              {isComponentVisible ? (
                <div ref={ref}>
                  <CustomHeaderPanel
                    aria-label="Header Panel"
                    expanded={switcher}
                  >
                    <Switcher aria-label="Switcher Container">
                      <CustomSwitcherItem isSelected={false} aria-label="info">
                        <S.ProfileImageContainer
                          style={{
                            backgroundColor: profileImage ? "white" : "none",
                            backgroundImage: profileImage
                              ? `url(${profileImage})`
                              : `url("/blank.png")`,
                            width: "64px",
                            height: "64px",
                            filter: profileImage
                              ? "brightness(1)"
                              : "brightness(2)",
                          }}
                        />
                        <span
                          aria-label="User Name"
                          style={{ color: "#ffffff" }}
                        >
                          {userName ||
                            user.aliasName ||
                            user.givenName ||
                            user.fullName}
                        </span>
                      </CustomSwitcherItem>
                      <SwitcherDivider />
                      <S.SwitcherItem
                        data-id="nav-profile-btn"
                        onClick={() => toggleSwitcher("/account")}
                        aria-label={navbar.profile}
                        style={{ color: "#ffffff" }}
                      >
                        {navbar.profile}
                      </S.SwitcherItem>
                      <S.SwitcherItem
                        data-id="nav-badges-btn"
                        onClick={() => toggleSwitcher("/account/badges")}
                        aria-label="Badges"
                        style={{ color: "#ffffff" }}
                      >
                        {navbar.badges}
                      </S.SwitcherItem>
                      <S.SwitcherItem
                        data-id="nav-logout-btn"
                        onClick={logout}
                        aria-label={navbar.logout}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          lineHeight: "1",
                          color: "#ffffff",
                        }}
                      >
                        <Link
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          {navbar.logout}
                        </Link>
                        <ArrowRight16 style={{ marginLeft: "1rem" }} />
                      </S.SwitcherItem>
                    </Switcher>
                  </CustomHeaderPanel>
                </div>
              ) : null}
            </>
          ) : null}
          {!loggedIn && !mobile ? (
            <span className={SS.loginBtn}>
              <HeaderGlobalAction
                name={navbar.login_signup}
                className={`user-nav-button`}
                aria-label={navbar.login_signup}
                onClick={() => (window.location.href = "/login")}
                style={{ width: "auto", padding: "0 1rem" }}
                id="loginheader"
                data-id="nav-login-signup-btn"
              >
                {navbar.login_signup}
              </HeaderGlobalAction>
            </span>
          ) : null}
          {mobile === true ? (
            <HeaderGlobalAction
              aria-label="Menu"
              className="mobile-nav-button"
              isActive={mobileNavOpen}
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
              }}
              data-id="nav-mobile-menu-btn"
            >
              <Menu24 />
            </HeaderGlobalAction>
          ) : null}
        </HeaderGlobalBar>
        <HeaderPanel aria-label="Header Panel" expanded={mobileNavOpen}>
          <MobileNav
            id="MobileNav"
            loggedIn={loggedIn}
            history={history}
            logout={logout}
            broadcastLogOut={broadcastLogOut}
            close={() => setMobileNavOpen(false)}
            open={mobileNavOpen}
            navBarData={{ ...dashnav, ...navbar }}
          ></MobileNav>
        </HeaderPanel>
      </S.Header>
      {showPasswordExpiry && user.isPasswordExpirationWarned === false && (
        <PasswordWarningModal />
      )}
      <Content id="content">
        {loadingSession ? (
          <Loading />
        ) : (
          <>
            {children}
            {/(\/complete-profile\/*)/g.test(location.pathname) ||
            /(\/account\/*)/g.test(location.pathname) ||
            /(\/360dashboard\/*)/g.test(
              location.pathname.toLocaleLowerCase() || loadingSession
            ) ? undefined : (
              <AnonymousFooter />
            )}
          </>
        )}
      </Content>
    </UIShellContainer>
  );
};

export default UIShell;
