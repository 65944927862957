import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const FooterCMS = () => {
  const { data: localeData, loading: localeLoading } = useStrapi({
    cms: `i18n/locales`,
    getLocale: true
  });
  const [locales, setLocales] = useState([]);
  useEffect(() => {
    if (localeLoading) {
      setLocales([
        {
          id: 1,
          name: "English (en)",
          code: "en",
          isDefault: true,
        },
        {
          id: 2,
          name: "Español (es)",
          code: "es",
          isDefault: false,
        },
      ]);
      return;
    }
    if(localeData && Array.isArray(localeData)) {
      setLocales(localeData);
    }
  }, [localeData]);

  const { data: linksData, loading: linksLoading } = useStrapi({
    cms: `navigation-footer`
  });
  const [links, setLinks] = useState({});

  useEffect(() => {
    if (linksLoading) {
      setLinks({
        terms_of_use: "Terms of Use",
        privacy_policy: "Privacy Policy",
        feedback: "Feedback",
        modal_heading: "Update your preferred language?",
        modal_content_1: `Click "YES" to save `,
        modal_content_2: " as your preferred language.",
        modal_content_English: "English",
        modal_content_Spanish: "Spanish",
        yes_button: "YES",
        no_button: "NO"
      });
      return;
    }
    setLinks((state) => ({
      terms_of_use: linksData.items.terms_of_use || state.terms_of_use,
      privacy_policy: linksData.items.privacy_policy || state.privacy_policy,
      feedback: linksData.items.feedback || state.feedback,
      modal_heading: linksData.items.modal_heading || state.modal_heading,
      modal_content_1: linksData.items.modal_content_1 || state.modal_content_1,
      modal_content_2: linksData.items.modal_content_2 || state.modal_content_2,
      modal_content_English: linksData.items.modal_content_English || state.modal_content_English,
      modal_content_Spanish: linksData.items.modal_content_Spanish || state.modal_content_Spanish,
      yes_button: linksData.items.yes_button || state.yes_button,
      no_button: linksData.items.no_button || state.no_button
    }));
  }, [linksData]);

  const { data: appData, loading: appLoading } = useStrapi({
    cms: "component-app-name"
  });
  const [app, setApp] = useState("");
  useEffect(() => {
    if (appLoading) {
      setApp("MyWayfinder");
      return;
    }
    setApp((state) => appData.name || state);
  }, [appData]);

  return { app, locales, links };
};
