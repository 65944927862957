import styled from "styled-components";
import { Link as Lk } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary.darkBlue};
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 0 1rem;
  color: #ffffff;
  flex-flow: column nowrap;
  align-items: center;
  @media ${(props) => props.theme.breakpoints.sm} {
    flex-flow: row nowrap;
  }
  & .footer-nav {
    display: flex;
  }
`;
export const Img = styled.img`
  width: 43px;
  padding: 0.5rem;
`;

export const Logo = styled.h1`
  font-weight: 600;
  font-size: 1rem;
`;
export const Link = styled(Lk)`
  padding: 1.25rem 1rem;
  color: #ffffff;
  text-decoration: none;
  padding: 0.75rem 0.5rem;
  &:hover {
    color: ${(props) => props.theme.colors.accent.mediumBlue};
  }
  text-align: center;
  font-weight: bold;
`;
export const LogoContent = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0.75rem 0.5rem;
  color: #ffffff;
  align-items: center;
  margin-right: 3rem;
`;

export const LangContainer = styled.div`
  margin: 0.75rem 0;
  @media ${(props) => props.theme.breakpoints.sm} {
    margin-left: auto;
    padding: 0 1rem;
  }
`;

export const ImageLogo = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  & p {
    font-size: .75rem;
    font-weight: 500;
    text-align: right;
  }
  & img {
    height: 1rem;
  }
  & img {
    height: 1rem;
    width: initial;
  }
  padding: 1rem 0;
  @media screen and (min-width: 600px) {
    padding: 0;
    margin-left: auto;
    flex-wrap: wrap;
  }
`;
export const FooterNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 600px) {
    margin-bottom: 0rem;
    flex-direction: row;
    margin-right: 2rem;
  }
`;
