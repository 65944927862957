import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import kit from "ich-ui-kit";
import { Modal } from "carbon-components-react";
import { useTimeoutCMS } from "./cms";
import { LocaleContext } from "../../store/LocaleContext";
import { handleLogoutUtil } from "../../utils";

export const IdleTimer = ({ setLoadingLogout, channel }) => {
  const { locale, setLocale } = useContext(LocaleContext);
  const { cms } = useTimeoutCMS();
  const { user, clearUser } = useContext(kit.UserContext);
  const history = useHistory();

  const minutesUntilTimeout = 20; // 20 minutes - time until log out
  const timeoutCountdownSeconds = 60; // 60 seconds - time between timeout warning message and being logged out

  // milliseconds * seconds * minutes
  const timeout = 1000 * 60 * minutesUntilTimeout; // converts minutesUntilTimeout into milliseconds
  const promptBeforeIdle = 1000 *  timeoutCountdownSeconds * 1; // converts timeoutCountdownSeconds into milliseconds 
  const [remaining, setRemaining] = useState(timeout); // possibly to add a 1 minute timer warning?
  const [modalOpen, setModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [leader, setLeader] = useState(true);

  const millisToSeconds = (millis) => {
    return Math.floor(millis/1000);
  }

  function millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = Math.floor((millis % 60000) / 1000);
    let display = ""
    let text_minutes = minutes === 1 ? cms.text_minutes : `${cms.text_minutes}s`;
    if(minutes > 0) {
      display += ` ${minutes} ${text_minutes} ${cms.text_and} `
    }
      display += seconds;
    return display;
  } 

  const handleOnActive = () => {
    // check if the use change password?
  };

  const timeOut = async () => {
    try {
      handleLogoutUtil({ channel, setLoadingLogout, clearUser, history, shouldSetLoadingLogoutToFalse: false })
      const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/protected/createTimeoutEvent`,
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${user.accessToken} ${user.idToken}`
        },
        body:
          user
      }
    );
    if(response.status === 200){
      setTimeout(() => {
        setLoadingLogout(false);
      }, "2000")   
    }
    } catch(error) {
      console.log(error);
    }
};

  useEffect(() => {
    if (
      Object.keys(user).filter((key) => key !== "notifications").length !== 0
    ) {
      start();
    } else {
      pause();
    }
  }, [user]);

  useEffect(() => {
        setInterval(() => {
          // setLeader(isLeader());
          setRemaining(getRemainingTime());
        }, 1000);
  }, []);

  const handleOnIdle = () => {
    if(user && user.idToken) {
      pause();
      setWarningModalOpen(false);
      setModalOpen(true);
        setTimeout(() => {
          if (leader) {
            timeOut();
          } else {
            handleLogoutUtil({channel, setLoadingLogout, clearUser, history});
          }
        }, "4000");
    }
  };

  const onPrompt = () => {
    if(user && user.idToken) {
      setWarningModalOpen(true);
    }
  };

  const onMessage = data => {
    switch (data.action) {
      case 'LOGOUT_USER':
        handleLogoutUtil({ channel, setLoadingLogout, clearUser, history, shouldSetLoadingLogoutToFalse: false })
        pause();
        setModalOpen(false);
        setWarningModalOpen(false);
        setTimeout(() => {
          setLoadingLogout(false);
        }, "1000")   
        break
      case 'STILL_HERE':
        setWarningModalOpen(false);
        activate();
        break
      default:
    }
  }

  const { getRemainingTime, activate, start, pause, isPrompted, message } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onMessage,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: true,
    syncTimers: 200
  });


  const handleStillHere = () => {
    setWarningModalOpen(false)
    message({ action: 'STILL_HERE' }, true)
  }

  const handleLogout = () => {
    clearUser();
    setModalOpen(false);
    setWarningModalOpen(false);
    message({ action: 'LOGOUT_USER' }, true)
  }


  return (
    <div>
      <Modal
        data-id="session-timeout-modal"
        aria-label={cms.text_expired_session}
        modalHeading={`${cms.text_expired_session}!`}
        aria-hidden="false"
        passiveModal
        open={modalOpen}
        onRequestClose={handleLogout}
      >
        <hr />
        <h3>
          {cms.text_timed_out}
          <br />
          {cms.text_redirect}
        </h3>
      </Modal>
      <Modal
        data-id="session-timeout-warning-modal"
        aria-label={cms.text_session_timeout_warning}
        modalHeading={cms.text_warning_heading}
        aria-hidden="false"
        open={warningModalOpen}
        size="md"
        onRequestClose={handleStillHere}
        primaryButtonText={
          <span>
            {cms.button_warning_continue}
            <span aria-hidden={true}>
              {`, ${cms.button_warning_continue_description}`}
            </span>
            <span className="srOnly">
              {` ${cms.button_warning_continue_description_aria}`}
            </span>
          </span>
        }
        secondaryButtonText={
          <span>
            {cms.button_warning_exit}
            <span aria-hidden={true}>
              {`, ${cms.button_warning_exit_description}`}
            </span>
            <span className="srOnly">
              {` ${cms.button_warning_exit_description_aria}`}
            </span>
          </span>
        }
        closeButtonLabel={
          `Close Popup, ${cms.button_warning_continue_description}`
        }
        onSecondarySubmit={handleLogout}
        onRequestSubmit={handleStillHere}
        preventCloseOnClickOutside
      >
        <hr />
        <h3>
          {cms.text_warning.replace(
            "[remaining_time]",
            millisToMinutesAndSeconds(remaining)
          )}
        </h3>
      </Modal>
    </div>
  );
};
