import React from "react";
import * as S from "./errorpage.styled";
import { useState } from "react";
import { useEffect } from "react";

const ErrorPage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <S.Container>
      <S.BannerContainer>
        <h1><strong>Uh oh!</strong></h1>
        <h2>This page is unavailable.</h2>
        <h2>We're working to get it back up.</h2>
        <br />
        <a href="/" style={{color: "black"}}>Home</a>
      </S.BannerContainer>
      {width > 1260 ?
      <S.WavesImage src="/sea-fish-no-middle.png" alt="pink, yellow, and green fish swimming in sea"></S.WavesImage> :
      <S.WavesImage src="/sea-fish.png" alt="pink, yellow, and green fish swimming in sea"></S.WavesImage>
      }
    </S.Container>
  );
};

export default ErrorPage;
