import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import "./App.css";

import kit from "ich-ui-kit";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { Loading, Modal, ModalBody } from "carbon-components-react";
import { ThemeProvider } from "styled-components";
//styling
import { GlobalStyle } from "./theme/globalStyle";
import { theme } from "./theme/theme";
import { handleLogoutUtil } from "./utils";

//Idle Timer
import { IdleTimer } from "./components/IdleTimer";
import { usePrivacyPolicyCMS } from "./components/LegalPages/cms";
import { PrivacyPolicyUpdateModal } from "./components/Modal-PrivacyPolicy";
import NewBadgeModal from "./components/NewBadgeModal";
import UIShell from "./components/UIShell";
import ErrorPage from "./pages/errorpage";
import { Fallback } from "./pages/loading-screen";
import { ActivityFeedProvider } from "./store/ActivityFeedContext";
import { FeedbackProvider } from "./store/FeedbackContext";
import { LocaleProvider } from "./store/LocaleContext";
import { NavProvider } from "./store/NavContext";
import { OverviewProvider } from "./store/OverviewContext";
import { GeolocationProvider } from "./store/GeolocationContext";
import { BusStopsProvider } from "./store/BusStopsContext";
import ConfirmEnrollment from "./pages/confirm-enrollment";
import { UserProfileProvider } from "./store/UserProfileContext";
// Utils
import { lazyRetry } from "./utils";

// Private pages
const Account = lazy(() =>
  lazyRetry(() => import("./pages/account"), "accountPage")
);
const Dashboard = lazy(() =>
  lazyRetry(() => import("./pages/client-dashboard"), "clientDashboardPage")
);
const CompleteProfileFlow = lazy(() =>
  lazyRetry(() => import("./pages/complete-profile"), "completeProfilePage")
);
const GuidedExperience = lazy(() =>
  lazyRetry(() => import("./pages/guided-experience"), "guidedExperiencePage")
);
const ActivityFeed = lazy(() =>
  lazyRetry(() => import("./pages/feed"), "activityFeedPage")
);
const CreateReferral = lazy(() =>
  lazyRetry(() => import("./pages/create-referral"), "createReferralPage")
);
const ReferralConfirmationPage = lazy(() =>
  lazyRetry(() => import("./pages/confirm-referral"), "confirmReferralPage")
);
const ReferralSurveyPage = lazy(() =>
  lazyRetry(() => import("./pages/referral-survey"), "referralSurveyPage")
);

//Public pages
const Home = lazy(() =>
  lazyRetry(() => import("./pages/anonymous"), "homePage")
);
const ImpactPage = lazy(() =>
  lazyRetry(() => import("./pages/system-stats"), "systemStatsPage")
);
const Login = lazy(() => lazyRetry(() => import("./pages/login"), "loginPage"));
const Register = lazy(() =>
  lazyRetry(() => import("./pages/create-account"), "createAccountPage")
);
const ServiceDirectoryProto = lazy(() =>
  lazyRetry(
    () => import("./pages/service-directory"),
    "serviceDirectoryProtoPage"
  )
);
const ElasticSearch = lazy(() =>
  lazyRetry(() => import("./pages/elasticsearch"), "serviceDirectoryPage")
);
const ProviderInfo = lazy(() =>
  lazyRetry(() => import("./pages/provider-info"), "providerInfoPage")
);
const ServiceInfoPage = lazy(() =>
  lazyRetry(() => import("./pages/service-info"), "serviceInfoPage")
);
const CheckIn = lazy(() =>
  lazyRetry(() => import("./pages/checkin"), "checkInPage")
);
const CheckInSummary = lazy(() =>
  lazyRetry(() => import("./pages/checkin-summary"), "checkInSummaryPage")
);
const Faq = lazy(() => lazyRetry(() => import("./pages/faq"), "faqPage"));
const AboutUs = lazy(() =>
  lazyRetry(() => import("./pages/about-us"), "aboutUsPage")
);
const ContactUs = lazy(() =>
  lazyRetry(() => import("./pages/contact-us"), "contactUsPage")
);
const MyHealthRecords = lazy(() =>
  lazyRetry(() => import("./pages/my-health-records"), "myHealthRecords")
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./pages/reset-password"), "resetPasswordPage")
);
const PrivacyPolicy = lazy(() =>
  lazyRetry(
    () => import("./components/LegalPages/PrivacyPolicyPage"),
    "privacyPolicyPage"
  )
);
const TermsOfUse = lazy(() =>
  lazyRetry(
    () => import("./components/LegalPages/TermsOfUsePage"),
    "termsOfUsePage"
  )
);

// Feature flags
const showPrototypeServiceDirectory =
  process.env.REACT_APP_ENABLE_PROTO_SERVICE_DIRECTORY || false;

function App() {
  const urlOrigin = window.location.href;
  const history = useHistory();
  let currentUser = sessionStorage.getItem("penn-user")
    ? JSON.parse(sessionStorage.getItem("penn-user"))
    : null;
  const [valid, setValid] = useState(true);
  const [loadingSession, setLoadingSession] = useState(false);
  const [exceededRequest, setExceededRequest] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(
    currentUser ? false : true
  );
  const mountedRef = useRef(false);

  const channel = new BroadcastChannel("monroe-sip-community");

  useEffect(() => {
    let currentUser = sessionStorage.getItem("penn-user")
      ? JSON.parse(sessionStorage.getItem("penn-user"))
      : null;
    let haveCurrentUser =
      currentUser &&
      Object.keys(currentUser).filter((key) => key !== "notifications").length >
        0;
    if (!haveCurrentUser) {
      channel.postMessage({ key: "get-info" });
    }
    setTimeout(() => {
      setLoadingLogout(false);
    }, "1000");
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      const selectedLocale = localStorage.getItem("locale");
      try {
        const currentUser = sessionStorage.getItem("penn-user")
          ? JSON.parse(sessionStorage.getItem("penn-user"))
          : null;
        if (currentUser?.accessToken && currentUser?.idToken) {
          let response = await fetch(
            process.env.REACT_APP_API_ENDPOINT + "/iam/checkSession",
            {
              method: "post",
              credentials: "include",
              headers: {
                Authorization: `Bearer ${currentUser?.accessToken} ${currentUser?.idToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          setExceededRequest(false);
          if (response.status === 200) {
            setValid(true);
            setLoadingSession(false);
            return;
          }
          if (response.status === 429) {
            setExceededRequest(true);
            setLoadingSession(false);
            return;
          }
        } else {
          setLoadingSession(false);
        }
        sessionStorage.removeItem("penn-user");
        localStorage.clear();
        setValid(false);
        setLoadingSession(false);
      } catch (error) {
        console.log(error);
        sessionStorage.removeItem("penn-user");
        localStorage.clear();
        setValid(false);
        setLoadingSession(false);
      } finally {
        if (selectedLocale) {
          localStorage.setItem("locale", selectedLocale);
        }
      }
    };

    mountedRef.current = true;
    if (mountedRef) {
      checkSession();
      setInterval(checkSession, 1000 * 60 * 5);
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  function PrivateRoute({ children, ...rest }) {
    let currentUser = sessionStorage.getItem("penn-user")
      ? JSON.parse(sessionStorage.getItem("penn-user"))
      : null;
    let haveCurrentUser =
      currentUser &&
      Object.keys(currentUser).filter((key) => key !== "notifications").length >
        0;
    const history = useHistory();
    if (loadingSession) {
      return <Loading />;
    } else if (
      Boolean(currentUser?.firstLogin) &&
      rest.location.pathname !== "/welcome"
    ) {
      if (currentUser.createdFrom.includes("provider-enrollment")) {
        return <Redirect to="/welcome-confirmation" />;
      } else {
        return <Redirect to="/welcome" />;
      }
    } else {
      return (
        <Route
          {...rest}
          render={() => {
            return valid === true || haveCurrentUser
              ? children
              : history.push("/login");
          }}
        />
      );
    }
  }

  const renderRoutes = ({ user, updateUser, clearUser }) => {
    channel.onmessage = function (e) {
      let currentUser = sessionStorage.getItem("penn-user")
        ? JSON.parse(sessionStorage.getItem("penn-user"))
        : null;
      let haveCurrentUser =
        currentUser &&
        Object.keys(currentUser).filter((key) => key !== "notifications")
          .length > 0;

      if (e.data?.key === "user" && e.data?.user && !haveCurrentUser) {
        let userData = JSON.parse(e.data.user);
        if (
          Object.keys(user).filter((key) => key !== "notifications").length ===
            0 &&
          userData &&
          Object.keys(userData).filter((key) => key !== "notifications")
            .length > 0 &&
          !haveCurrentUser
        ) {
          sessionStorage.setItem("penn-user", JSON.stringify(userData));
          updateUser(userData);
          if (urlOrigin.includes("login")) {
            window.location.href = "/";
          }
        }
      }
      if (e.data?.key === "get-info") {
        if (
          user &&
          Object.keys(user).filter((key) => key !== "notifications").length >
            0 &&
          haveCurrentUser
        ) {
          channel.postMessage({ user: JSON.stringify(user), key: "user" });
        }
      }
      if (e.data?.key === "logout") {
        handleLogoutUtil({ channel, setLoadingLogout, clearUser, shouldSetLoadingLogoutToFalse: true, postMessage: false });
      }
    };

    if (
      Object.keys(user).filter((key) => key !== "notifications").length !== 0 &&
      loadingLogout === false
    ) {
      return (
        <NavProvider>
          <ActivityFeedProvider>
            <OverviewProvider>
              <FeedbackProvider>
                {/* {(!user.policy_accepted && !user.firstLogin) && <PrivacyPolicyUpdateModal />} */}
                {/* {true && <PrivacyPolicyUpdateModal />} */}
                <>
                  {user.firstLogin ? (
                    <Suspense fallback={<Fallback />}>
                      {user.createdFrom.includes("provider-enrollment") ? (
                        <ConfirmEnrollment />
                      ) : (
                        <GuidedExperience />
                      )}
                    </Suspense>
                  ) : (
                    <UIShell
                      loadingSession={loadingSession}
                      exceededRequest={exceededRequest}
                      setLoadingLogout={setLoadingLogout}
                      channel={channel}
                    >
                      {!user.policyAccepted && <PrivacyPolicyUpdateModal />}
                      <NewBadgeModal />
                      <Switch>
                        <Route path="/login">
                          <Suspense fallback={<Fallback />}>
                            <Login channel={channel} />
                          </Suspense>
                        </Route>
                        <PrivateRoute path="/account">
                          <Suspense fallback={<Fallback />}>
                            <Account channel={channel} setLoadingLogout={setLoadingLogout} />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/about">
                          <Suspense fallback={<Fallback />}>
                            <AboutUs />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/faq">
                          <Suspense fallback={<Fallback />}>
                            <Faq />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/my-health-records">
                          <Suspense fallback={<Fallback />}>
                            <MyHealthRecords />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/service-directory">
                          <Suspense fallback={<Fallback />}>
                            <ElasticSearch />
                          </Suspense>
                        </PrivateRoute>
                        {showPrototypeServiceDirectory && (
                          <PrivateRoute
                            exact
                            path="/prototype/service-directory"
                          >
                            <Suspense fallback={<Fallback />}>
                              <ServiceDirectoryProto />
                            </Suspense>
                          </PrivateRoute>
                        )}
                        <PrivateRoute path="/complete-profile">
                          <Suspense fallback={<Fallback />}>
                            <CompleteProfileFlow />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/account">
                          <Suspense fallback={<Fallback />}>
                            <Account channel={channel} setLoadingLogout={setLoadingLogout} />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/feed">
                          <Suspense fallback={<Fallback />}>
                            <ActivityFeed />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/survey">
                          <Suspense fallback={<Fallback />}>
                            <ReferralSurveyPage />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/service-directory">
                          <Suspense fallback={<Fallback />}>
                            <ElasticSearch />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/welcome-confirmation">
                          <Suspense fallback={<Fallback />}>
                            <ConfirmEnrollment />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/welcome">
                          <Suspense fallback={<Fallback />}>
                            <GuidedExperience />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/360dashboard">
                          <Suspense fallback={<Fallback />}>
                            <Dashboard channel={channel} />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/checkin/view">
                          <Suspense fallback={<Fallback />}>
                            <CheckInSummary />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/checkin">
                          <Suspense fallback={<Fallback />}>
                            <CheckIn />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/reset-password">
                          <Suspense fallback={<Fallback />}>
                            <ResetPassword />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/provider-information">
                          <Suspense fallback={<Fallback />}>
                            <ProviderInfo />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/service-information">
                          <Suspense fallback={<Fallback />}>
                            <ServiceInfoPage />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path="/referral">
                          <Suspense fallback={<Fallback />}>
                            <CreateReferral />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/referral/service-directory">
                          <Suspense fallback={<Fallback />}>
                            <ElasticSearch />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute path="/referral/confirmation">
                          <Suspense fallback={<Fallback />}>
                            <ReferralConfirmationPage />
                          </Suspense>
                        </PrivateRoute>
                        <Route path="/error">
                          <Suspense fallback={<Fallback />}>
                            <ErrorPage />
                          </Suspense>
                        </Route>
                        <Route exact path="/system-stats">
                          <Suspense fallback={<Fallback />}>
                            <ImpactPage />
                          </Suspense>
                        </Route>
                        <Route path="/terms-of-use">
                          <Suspense fallback={<Fallback />}>
                            <TermsOfUse />
                          </Suspense>
                        </Route>
                        <Route path="/privacy-policy">
                          <Suspense fallback={<Fallback />}>
                            <PrivacyPolicy />
                          </Suspense>
                        </Route>
                        <Route path="/contact-us">
                          <Suspense fallback={<Fallback />}>
                            <ContactUs />
                          </Suspense>
                        </Route>
                        {/* <Route path="/under-construction">
                          <UnderConstruction />
                        </Route> */}
                        <PrivateRoute path="/">
                          <Suspense fallback={<Fallback />}>
                            <Home loadingSession={loadingSession} />
                          </Suspense>
                        </PrivateRoute>
                        <Route path="*">
                          <Suspense fallback={<Fallback />}>
                            <ErrorPage />
                          </Suspense>
                        </Route>
                      </Switch>
                    </UIShell>
                  )}
                </>
              </FeedbackProvider>
            </OverviewProvider>
          </ActivityFeedProvider>
        </NavProvider>
      );
    }

    return (
      <NavProvider>
        <Router>
          <UIShell
            loadingSession={loadingSession}
            exceededRequest={exceededRequest}
            setLoadingLogout={setLoadingLogout}
            channel={channel}
          >
            <Switch>
              <Route exact path="/system-stats">
                <Suspense fallback={<Fallback />}>
                  <ImpactPage />
                </Suspense>
              </Route>
              <Route exact path="/login">
                <Suspense fallback={<Fallback />}>
                  <Login channel={channel} />
                </Suspense>
              </Route>
              <Route path="/create-account">
                <Suspense fallback={<Fallback />}>
                  <Register />
                </Suspense>
              </Route>
              <Route exact path="/service-directory">
                <Suspense fallback={<Fallback />}>
                  <ElasticSearch />
                </Suspense>
              </Route>
              {showPrototypeServiceDirectory && (
                <Route exact path="/prototype/service-directory">
                  <Suspense fallback={<Fallback />}>
                    <ServiceDirectoryProto />
                  </Suspense>
                </Route>
              )}
              <Route path="/provider-information">
                <Suspense fallback={<Fallback />}>
                  <ProviderInfo />
                </Suspense>
              </Route>
              <Route path="/service-information">
                <Suspense fallback={<Fallback />}>
                  <ServiceInfoPage />
                </Suspense>
              </Route>
              <Route exact path="/checkin/view">
                <Suspense fallback={<Fallback />}>
                  <CheckInSummary />
                </Suspense>
              </Route>
              <Route exact path="/checkin">
                <Suspense fallback={<Fallback />}>
                  <CheckIn />
                </Suspense>
              </Route>
              <Route path="/about">
                <Suspense fallback={<Fallback />}>
                  <AboutUs />
                </Suspense>
              </Route>
              <Route path="/faq">
                <Suspense fallback={<Fallback />}>
                  <Faq />
                </Suspense>
              </Route>
              <Route path="/my-health-records">
                <Suspense fallback={<Fallback />}>
                  <MyHealthRecords />
                </Suspense>
              </Route>
              <Route path="/reset-password">
                <Suspense fallback={<Fallback />}>
                  <ResetPassword />
                </Suspense>
              </Route>
              <Route path="/terms-of-use">
                <Suspense fallback={<Fallback />}>
                  <TermsOfUse />
                </Suspense>
              </Route>
              <Route path="/privacy-policy">
                <Suspense fallback={<Fallback />}>
                  <PrivacyPolicy />
                </Suspense>
              </Route>
              <Route path="/contact-us">
                <Suspense fallback={<Fallback />}>
                  <ContactUs />
                </Suspense>
              </Route>
              <Route exact path="/">
                <Suspense fallback={<Fallback />}>
                  <Home loadingSession={loadingSession} />
                </Suspense>
              </Route>
              <Route>
                {loadingLogout ? (
                  <Suspense fallback={<Fallback />}>
                    <Login channel={channel} />
                  </Suspense>
                ) : (
                  <Suspense fallback={<Fallback />}>
                    <ErrorPage />
                  </Suspense>
                )}
              </Route>
            </Switch>
          </UIShell>
        </Router>
      </NavProvider>
    );
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle theme={theme} />
        <GeolocationProvider>
          <LocaleProvider>
            <kit.ApiProvider sessionStorageKey="penn-user">
              <kit.UserProvider sessionStorageKey="penn-user">
                <BusStopsProvider>
                  <UserProfileProvider>
                    <IdleTimer
                      setLoadingLogout={setLoadingLogout}
                      channel={channel}
                    />
                    <kit.UserConsumer>{renderRoutes}</kit.UserConsumer>
                  </UserProfileProvider>
                </BusStopsProvider>
              </kit.UserProvider>
            </kit.ApiProvider>
          </LocaleProvider>
        </GeolocationProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
