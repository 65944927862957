import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useLayoutEffect
} from "react";
import { strapi } from "../axios/strapi.instance";
import { LocaleContext } from "../store/LocaleContext";

export const useStrapi = ({ cms, getLocale = false, populate = null }) => {
  const mountedRef = useRef(0);
  const { locale } = useContext(LocaleContext);

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const memoGetRecords = useCallback(async () => {

    try {
      const res = await strapi.get(
        `/api/${cms}?locale=${locale?.code || "en"}&${populate ? populate : 'populate=*'}`
      );
      if (getLocale) {
        setData(res);
        return;
      }
      if (!res.data) return;
      setData(res.data.attributes);
    } catch (e) {
      console.log(e);
    }
  }, [locale]);

  useLayoutEffect(() => {
    try {
      setLoading(true);
      memoGetRecords();
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  }, [cms, strapi, locale]);

  return { data, loading, error };
};
