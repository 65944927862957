import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";

export const useNavbarCMS = () => {
  const navInit = {
    home: "Home",
    service_directory: "Service Directory",
    dashboard: "Dashboard",
    activity_feed: "Activity Feed",
    our_impact: "Our Impact",
    profile: "Profile",
    feedback: "Feedback",
    logout: "Logout",
    login_signup: "Login/Signup",
    need_help_chatbot: "Need Help? Open Chatbot",
    badges: "Badges"
  };
  const { data: navData, loading: navLoading } = useStrapi({
    cms: `navigation-navbar`
  });

  const [navbar, setNavbar] = useState(navInit);

  useEffect(() => {
    if (navLoading) {
      setNavbar(navInit);
      return;
    }
    setNavbar((state) => ({
      home: navData.items.home || state.home,
      service_directory:
        navData.items.service_directory || state.service_directory,
      dashboard: navData.items.dashboard || state.dashboard,
      activity_feed: navData.items.activity_feed || state.activity_feed,
      our_impact: navData.items.our_impact || state.our_impact,
      profile: navData.items.profile || state.profile,
      feedback: navData.items.feedback || state.feedback,
      logout: navData.items.logout || state.logout,
      login_signup: navData.items.login_signup || state.login_signup,
      need_help_chatbot: navData.items.need_help_chatbot || state.need_help_chatbot,
      badges: navData.items.badges || state.badges
    }));
  }, [navData]);

  const domainInit = {
    dependent_care: "Dependent Care",
    education: "Education",
    employment: "Employment",
    financial: "Financial",
    food: "Food",
    health: "Health",
    housing: "Housing",
    income: "Income",
    safety: "Safety",
    social_connections: "Social Connections",
    transportation: "Transportation"
  };
  const { data: domainData, loading: domainLoading } = useStrapi({
    cms: `list-domain`
  });
  const [domain, setDomain] = useState(domainInit);
  useEffect(() => {
    if (domainLoading) {
      setDomain(domainInit);
      return;
    }
    setDomain((state) => ({
      food: domainData?.food || state.food,
      health: domainData.health || state.health,
      housing: domainData?.housing || state.housing,
      education: domainData?.education || state.education,
      social_connections:
        domainData?.social_connections || state.social_connections,
      safety: domainData?.safety || state.safety,
      income: domainData?.income || state.income,
      transportation: domainData?.transportation || state.transportation,
      dependent_care: domainData?.dependent_care || state.dependent_care,
      financial: domainData?.financial || state.financial,
      employment: domainData?.employment || state.employment
    }));
  }, [domainData]);

  const { data: appData, loading: appLoading } = useStrapi({
    cms: "component-app-name"
  });
  const [app, setApp] = useState("MyWayfinder");
  useEffect(() => {
    if (appLoading) {
      setApp();
      return;
    }
    setApp((state) => appData.name || state);
  }, [appData]);

  const dashnavInit = {
    label_name: "Name",
    label_email: "Label",
    label_mobile: "Mobile",
    overview: "My Well-Being",
    demographic_data: "About Me",
    care_teams: "My Care Team",
    service_requests: "Service Requests",
    check_ins: "Check-Ins",
    event_history: "Event History"
  };

  const { data: dashnavData, loading: dashnavLoading } = useStrapi({
    cms: `navigation-sidebar-dashboard`
  });
  const [dashnav, setDashnav] = useState(dashnavInit);
  useEffect(() => {
    if (dashnavLoading) {
      setDashnav(dashnavInit);
      return;
    }

    setDashnav((state) => ({
      overview: dashnavData.items.overview || state.overview,
      demographic_data:
        dashnavData.items.demographic_data || state.demographic_data,
      care_teams: dashnavData.items.care_team || state.care_teams,
      service_requests:
        dashnavData.items.service_requests || state.service_requests,
      check_ins: dashnavData.items.check_ins || state.check_ins,
      event_history: dashnavData.items.event_history || state.event_history,
      label_name: dashnavData.label_name || state.label_name,
      label_email: dashnavData.label_email || state.label_email,
      label_mobile: dashnavData.label_mobile || state.label_mobile
    }));
  }, [dashnavData]);

  return { navbar, domain, app, dashnav };
};
