import styled from 'styled-components';
import { Button } from 'carbon-components-react';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

export const TextContainer = styled.div`
    padding: 0 5rem;
    text-align: center;

    @media only screen and (max-width: 600px) {
        padding: 0;
    }
`;

export const Badge = styled.img`
    width: 150px;
    height: 150px;
    align-self: center;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
    margin: 0 3rem 1rem 0;
    
    @media only screen and (max-width: 600px) {
        margin: auto;
    }
`;
