import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import kit from 'ich-ui-kit';
import { Modal } from 'carbon-components-react';
import {
  Content,
  TextContainer,
  Badge,
  StyledButton as Button,
} from './static';

const NewBadgeModal = () => {
  const { user, setUser, updateUser } = useContext(kit.UserContext);
  const history = useHistory();

  const handleViewAllBadges = () => {
    updateUser({ newBadge: null });
    history.push('/account');
  };

  return (
    <Modal
      passiveModal
      open={user.newBadge}
      onRequestClose={() => updateUser({ newBadge: null })}
    >
      {user.newBadge && (
        <Content>
          <Badge src='./icons/BuildingCareTeamBadge.png' />
          <br />
          <TextContainer>
            <p style={{ fontSize: '2.25rem', marginBottom: '2rem' }}>
              <strong>{user.newBadge.name === 'Become a Member' ? "Welcome to PA R&RT!" :  "Congrats on your first referral!"}</strong>
            </p>
            <br />
            {/* <p
              style={{
                marginBottom: '2rem',
                color: '#0062FF',
                fontWeight: 200,
                fontFamily: 'IBM Plex Mono',
                fontSize: '48px',
              }}
            >
              +{user?.newBadge?.points} Health Points
            </p> */}
          </TextContainer>
          <Button kind='tertiary' onClick={handleViewAllBadges}>
            View Your Profile
          </Button>
        </Content>
      )}
    </Modal>
  );
};

export default NewBadgeModal;
