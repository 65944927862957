import { DropdownMenuContainer, DropdownMenuListItem } from "../uishell.styled";
import { useHistory } from "react-router";

const DropdownMenu = ({
  cmsData,
  setIs360DashboardMenuHovered,
  setIsResourcesMenuHovered
}) => {
  const history = useHistory();

  if (setIs360DashboardMenuHovered) {
    const menuItems360 = [
      {
        text: cmsData.overview,
        path: "/360dashboard/overview"
      },
      {
        text: cmsData.demographic_data,
        path: "/360dashboard/demographic-data"
      },
      {
        text: cmsData.care_teams,
        path: "/360dashboard/care-team"
      },
      {
        text: cmsData.service_requests,
        path: "/360dashboard/service-requests"
      },
      {
        text: cmsData.check_ins,
        path: "/360dashboard/check-ins"
      },
      {
        text: cmsData.event_history,
        path: "/360dashboard/longitudinal-record"
      }
    ];

    return (
      <DropdownMenuContainer
        onMouseEnter={() => setIs360DashboardMenuHovered(true)}
      >
        <ul>
          {menuItems360.map((item, i) => (
            <DropdownMenuListItem
              key={i}
              onClick={() => {
                history.push(item.path);
                setIs360DashboardMenuHovered(false);
              }}
              data-id={`nav-dashboard-dropdown-${item.text.includes(" ") ? item.text.split(" ")[0] : item.text}`}
            >
              {item.text}
            </DropdownMenuListItem>
          ))}
        </ul>
      </DropdownMenuContainer>
    );
  } else if (setIsResourcesMenuHovered) {
    const menuItemsDomains = [
      {
        text: cmsData.dependent_care,
        value: "Dependent Care"
      },
      {
        text: cmsData.education,
        value: "Education"
      },
      {
        text: cmsData.employment,
        value: "Employment"
      },
      {
        text: cmsData.financial,
        value: "Financial"
      },
      {
        text: cmsData.food,
        value: "Food"
      },
      {
        text: cmsData.health,
        value: "Health"
      },
      {
        text: cmsData.housing,
        value: "Housing"
      },
      {
        text: cmsData.income,
        value: "Income"
      },
      {
        text: cmsData.safety,
        value: "Safety"
      },
      {
        text: cmsData.social_connections,
        value: "Social Connections"
      },
      {
        text: cmsData.transportation,
        value: "Transportation"
      }
    ];
    return (
      <DropdownMenuContainer
        onMouseEnter={() => setIsResourcesMenuHovered(true)}
      >
        <ul>
          {menuItemsDomains.map((item, i) => (
            <DropdownMenuListItem
              key={i}
              data-id={`nav-service-directory-dropdown-${item.text.includes(" ") ? item.text.split(" ")[0] : item.text}`}
              onClick={() => {
                history.push({
                  pathname: "/service-directory",
                  state: {
                    from: "nav",
                    inputType: "domain",
                    keyword: item.value,
                    domain: item.value,
                    display: item.text,
                    subCategory: [],
                    isSubCategory: false
                  }
                });
                setIsResourcesMenuHovered(false);
              }}
            >
              {item.text}
            </DropdownMenuListItem>
          ))}
        </ul>
      </DropdownMenuContainer>
    );
  }
};

export default DropdownMenu;
