import styled from "styled-components";
import { Dropdown, TextInput, Button } from "carbon-components-react";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  height: auto;
  overflow-y: auto;
  position: relative;
  /* background: paleturquoise; */
  padding: 1rem;

  && .bx--modal-content {
    overflow-y: visible;
    margin: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      margin: 1rem 0rem 1rem 0rem;
    }
  }

  && .bx--modal-container {
    overflow: visible;
    margin: 3rem;
    padding: 0rem;
    height: auto;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      max-width: 1260px;
    }
    @media screen and (max-width: 768px) {
      margin: 3rem 0rem 0rem 0rem;
    }
  }
`;

export const Header = styled.div`
  margin: 1.5rem 0;
`;

export const Content = styled.div`
  padding: 0;
  height: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  /* max-width: 20rem; */
`;

export const StyledTextInput = styled(TextInput)`
  /* margin-top: 0.5rem; */
  /* max-width: 20rem; */
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.accent.mediumBlue};
`;
