import { useEffect, useState } from "react";
import { useStrapi } from "../../hooks/useStrapi";
import { sortStates } from "carbon-components-react/lib/components/DataTable/state/sorting";

export const useAccountCMS = () => {
  const init = {
    heading: "Create My Account Guidelines",
    content:
      "Check the box if you agree to create an account and participate in Phase 1 of the *SIP Data Ecosystem*. As the *SIP Data Ecosystem* grows, you will be able to update your consent to continue participating.\n\nTo create an account, you will need to share a little information about yourself. You will be able to add more if you want. All that you share will be used to create your Dashboard which you can choose to share or keep private.\n",
    continue_as_guest: "Continue as Guest",
    btn_next: "Next",
    create_account_benefits_title: "Benefits of joining MyWayfinder",
    create_account_join_title: "Join MyWayfinder",
    input_prompt_dob: "Enter your birth date",
    input_prompt_email: "Enter your email address",
    input_prompt_password: "Create a password",
    input_prompt_password_confirm: "Confirm your password",
    video_link: "https://www.youtube.com/embed/q5L2bfnODZE",
    asl_video_link: "https://www.youtube.com/embed/9l36gRrToGo",
    asl_btn: "View video in American Sign Language",
    asl_original_btn: "View video in English",
    btn_download: "Download Guidelines",
    consent_0: "Joining MyWayfinder lets you find and connect with service providers who can help you improve your wellbeing.\n\n\nIf you join, you’ll get your own MyWayfinder Dashboard to keep track of how you are doing and make it easier to connect with your service providers.",
    consent_1: "First, you will need to provide a little information about yourself.",
    consent_2: "Next, you can tell MyWayfinder how you feel you are doing in different areas about of your life. You control how much or how little information you share. The information you share will be used to recommend services for you.\n\nYour MyWayfinder Dashboard is accessible only by you and anyone that you choose to share it with. You can share your information with organizations and individuals that provide services by adding them to your MyWayfinder Care Team.",
    consent_title: "Create My Account Guidelines",
    consent_agree_title: "Agree to Join",
    consent_agree_btn: "Agree",
    consent_learn_btn: "Learn More",
    link_text_terms: "Terms and Conditions",
    link_text_privacy_policy: "Privacy Policy",
    error_duplicate_email: "An account already exists for email",
    error_duplicate_email_prompt: "Try another email",
    error_registration: "Registration did not go through. This is likely because of a network error.",
    error_registration_prompt: "Please try again or continue as Guest",
    modal_signup_aria: "Prompt Signup Modal",
    modal_create_mwf_account: "Create a MyWayfinder Account",
    modal_btn_create_account: "Create Account",
    modal_btn_done: "Done",
    modal_btn_sending: "Sending",
    modal_btn_cancel: "Cancel",
    modal_content: "You need a MyWayfinder Account to create referrals, add service providers to your care team, and bookmark service providers. \n\n With MyWayfinder, you can build your own path to reach your personal goals. With access to tools and services, you can feel empowered to connect with resources that meet your needs.",
    error_required_email:	"Email address is required",
    error_dob:	"Must be at least 13 years of age",
    error_confirm_password:	"Passwords must match and meet requirements",
    error_valid_email:	"Please enter a valid email address",
    error_valid_date:	"Must be valid date",
    place_holder_label:{
      place_holder_email: "Email Address",
    }
  };

  const { data, loading } = useStrapi({
    cms: `page-create-account`
  });

  const [guidelines, setGuidlines] = useState(init);
  useEffect(() => {
    if (loading) {
      setGuidlines(init);
      return;
    }
    setGuidlines((state) => ({
      heading: data.headeing || state.heading,
      content: data.content || state.content,
      continue_as_guest: data.continue_as_guest || state.continue_as_guest,
      btn_next: data.btn_next || state.btn_next,
      create_account_benefits_title: data.create_account_benefits_title || state.create_account_benefits_title,
      create_account_join_title: data.create_account_join_title|| state.create_account_join_title,
      input_prompt_dob: data.input_prompt_dob || state.input_prompt_dob,
      input_prompt_email: data.input_prompt_email || state.input_prompt_email,
      input_prompt_password: data.input_prompt_password || state.input_prompt_password,
      input_prompt_password_confirm: data.input_prompt_password_confirm || state.input_prompt_password_confirm,
      video_link: data.video_link || state.video_link,
      asl_video_link: data.asl_video_link || state.asl_video_link,
      asl_btn: data.asl_btn || state.asl_btn,
      asl_original_btn: data.asl_original_btn || state.asl_original_btn,
      btn_download: data.btn_download || state.btn_download,
      consent_0: data.consent_0 || state.consent_0,
      consent_1: data.consent_1 || state.consent_1,
      consent_2: data.consent_2 || state.consent_2,
      consent_title: data.consent_title || state.consent_title,
      consent_agree_title:  data.consent_agree_title || state.consent_agree_title,
      consent_agree_btn: data.consent_agree_btn || state.consent_agree_btn,
      consent_learn_btn: data.consent_learn_btn || state.consent_learn_btn,
      link_text_terms: data.link_text_terms || state.link_text_terms,
      link_text_privacy_policy: data.link_text_privacy_policy|| state.link_text_privacy_policy,
      error_duplicate_email: data.error_duplicate_email || state.error_duplicate_email,
      error_duplicate_email_prompt: data.error_duplicate_email_prompt || state.error_duplicate_email_prompt,
      error_registration: data.error_registration || state.error_registration,
      error_registration_prompt: data.error_registration_prompt || state.error_registration_prompt,
      modal_signup_aria:  data.modal_signup_aria || state.modal_signup_aria,
      modal_create_mwf_account: data.modal_create_mwf_account || state.modal_create_mwf_account,
      modal_btn_create_account: data.modal_btn_create_account || state.modal_btn_create_account,
      modal_btn_done: data.modal_btn_done || state.modal_btn_done,
      modal_btn_sending: data.modal_btn_sending || state.modal_btn_sending,
      modal_btn_cancel: data.modal_btn_cancel || state.modal_btn_cancel,
      modal_content: data.modal_content || state.modal_content,
      error_required_email: data.error_required_email || state.error_required_email,
      error_dob: data.error_dob || state.error_dob,
      error_confirm_password: data.error_confirm_password || state.error_confirm_password,
      error_valid_email: data.error_valid_email || state.error_valid_email,
      error_valid_date:	 data.error_valid_date || state.error_valid_date,
      place_holder_label:{
        place_holder_email: data.place_holder_label?.place_holder_email || state.place_holder_label?.place_holder_email,
      }
    }));
  }, [data]);

  return { guidelines, data, loading };
};
