import React, { useContext, useEffect, useState } from "react";
import S from "../confirm-enrollment.module.scss";
import kit from "ich-ui-kit";
import {
  Button,
  TextInput,
  Loading,
  PasswordInput
} from "carbon-components-react";
import { CustomToolTip } from "../../../validations/Tooltip";
import useForm from "../../../hooks/useForm";
import validate from "../../../validations/registerValidator";
import { useHistory } from "react-router-dom";
import { shouldAutoComplete } from "../../../utils";
import { useAccountCMS } from "../../../components/create-account/cms";

const ConfirmEnrollment1 = ({ page, setPage, user, updateUser, wrongPasswordToast, setWrongPasswordToast, cms }) => {
  const { apiService } = useContext(kit.ApiContext);
  const history = useHistory();
  const { guidelines } = useAccountCMS();
  const { values, setValues, errors, setIsDisabled, handleChange } =
    useForm(validate, guidelines);
  const [showTool, setShowTool] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setValues({
      password: "",
      confirmPassword: "",
    });

    setIsDisabled({
      confirmPassword: true,
    });
  }, [setValues, setIsDisabled]);

  const toast = (set) => {
    set(true);
    setTimeout(() => {
      set(false);
    }, 3000);
  };

  const handleReset = async (e) => {
    setLoading(true);
    let response = await apiService.put(`/iam/change-credentials`, {
      username: user.username,
      password: values.password,
      currentPassword: values.confirmPassword,
      firstLogin: true,
    });
    if (response.ok === true) {
      updateUser({ firstLogin: null });
      history.push('/home');
    } else {
      toast(setWrongPasswordToast);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className={S.content}>
        <div className={S.header0}>{cms.title_confirm_enrollment}</div>
        <div className={S.paragraph}>
         {cms.prompt_update_password}
        </div>
        <div className={S.paragraph}>
          <div style={{ display: "flex", width: "300px" }}>
            <PasswordInput
              labelText={<div className={S.textInputTitle}>{cms.text_set_new_password}</div>}
              onChange={handleChange}
              id="password"
              value={values.password || ""}
              type={passwordType}
              invalid={!!errors.confirmPassword}
              tooltipAlignment="end"
              onClick={() => setShowTool(true)}
              onFocus={() => setShowTool(!showTool)}
              onBlur={() => setShowTool(!showTool)}
              className={S.textInput}
              autoComplete={shouldAutoComplete}
              showPasswordLabel={cms.label_show_password}
              hidePasswordLabel={cms.label_hide_password}
            />
            <CustomToolTip
              errors={errors.password || []}
              open={showTool}
              showIcon={false}
            />
          </div>
          <div style={{ display: "flex", width: "300px" }}>
            <PasswordInput
              labelText={<div className={S.textInputTitle}>{cms.text_confirm_password}</div>}
              onChange={handleChange}
              id="confirmPassword"
              value={values.confirmPassword || ""}
              type={confirmType}
              invalid={!!errors.confirmPassword}
              invalidText={errors.confirmPassword}
              className={S.textInput}
              autoComplete={shouldAutoComplete}
              showPasswordLabel={cms.label_show_password}
              hidePasswordLabel={cms.label_hide_password}
            />
          </div>
        </div>
      </div>
      <div className={S.bottom}>
        <div className={S.paragraph}>
          <div>
            <Button
              className={S.buttonPrimary}
              kind="primary"
              disabled={loading}
              onClick={() => handleReset()}
            >
              {cms.btn_reset_password}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEnrollment1;
