import React, { useContext, useEffect, useState } from "react";
import S from "./confirm-enrollment.module.scss";
import kit from "ich-ui-kit";
import {
  Button,
  InlineLoading,
  Loading,
  Modal,
  ToastNotification,
  Header as Hr,
  HeaderName
} from "carbon-components-react";
import ReactMarkdown from "react-markdown";
import { useAccountCMS } from "../account/cms";
import LanguageDropdown from "../../components/LanguageDropdown";
import ConfirmEnrollment0 from "./confirm-enrollment-0";
import ConfirmEnrollment1 from "./confirm-enrollment-1";
import styled from "styled-components";

const ConfirmEnrollment = () => {
  const { user, clearUser, updateUser } = useContext(kit.UserContext);
  const [page, setPage] = useState(0);
  const { account } = useAccountCMS();
  const [wrongPasswordToast, setWrongPasswordToast] = useState(false);

  const renderContent = () => {
    switch (page) {
      case 1:
        return (
          <ConfirmEnrollment1
            page={page}
            user={user}
            updateUser={updateUser}
            setPage={setPage}
            wrongPasswordToast={wrongPasswordToast}
            setWrongPasswordToast={setWrongPasswordToast}
            cms={account.consents}
          />
        );
      case 0:
      default:
        return (
          <ConfirmEnrollment0
            page={page}
            user={user}
            clearUser={clearUser}
            setPage={setPage}
            cms={account.consents}
          />
        );
    }
  };

  return (
    <div className={S.parentContainer}>
      <Header>
        <HeaderName
          prefix=""
        >
          <span className="srOnly">MyWayfinder</span>
        </HeaderName>
        <LanguageDropdown />
      </Header>
            {wrongPasswordToast && (
        <ToastNotification
          kind="error"
          title={account.consents.error_changing_password}
          caption={account.consents.error_previous_passwords}
          style={{ marginBottom: ".5rem", position: "absolute", right: "0" }}
        />
      )}
      <div className={S.page}>
      {renderContent()}
      </div>
    </div>
  );
};

export default ConfirmEnrollment;

export const Header = styled(Hr)`
  /* background: ${(props) => props.theme.colors.primary.darkBlue} !important; */
  position: absolute;
  background: transparent;
  border-bottom: none;
  display:flex;
  justify-content: space-between;
  padding-right: 1.5rem;
  @media screen and (max-width: 630px) {
    position: relative;
    padding-right: .5rem;
    background: #fff;
  }
`;
