import React, { useEffect, useState } from "react";

import { useStrapi } from "../../../../hooks/useStrapi";

export const FooterCMS = () => {
  const { data: localeData, loading: localeLoading } = useStrapi({
    cms: `i18n/locales`,
    getLocale: true
  });
  const [locales, setLocales] = useState([]);
  useEffect(() => {
    if (localeLoading) {
      setLocales([
        {
          id: 1,
          name: "English (en)",
          code: "en",
          isDefault: true,
        },
        {
          id: 2,
          name: "Español (es)",
          code: "es",
          isDefault: false,
        },
      ]);      
      return;
    }
    if(localeData && Array.isArray(localeData)) {
      setLocales(localeData);
    }
  }, [localeData]);

  const { data: linksData, loading: linksLoading } = useStrapi({
    cms: `navigation-footer`,
    populate: 'populate[0]=items&populate[1]=items.togetherNowIconWhite'
  });
  const init = {
    terms_of_use: "Terms of Use",
    privacy_policy: "Privacy Policy",
    feedback: "Feedback",
    contact_us: "Get Help",
    faq: "Frequently Asked Questions",
    about_us: "About Us",
    our_impact: "Our Impact",
    togetherNowStamp: "Brought to you by",
    togetherNowIconWhite: "",
  };
  const [links, setLinks] = useState(init);

  useEffect(() => {
    if (linksLoading) {
      setLinks(init);
      return;
    }
    setLinks((state) => ({
      terms_of_use: linksData.items.terms_of_use || state.terms_of_use,
      privacy_policy: linksData.items.privacy_policy || state.privacy_policy,
      feedback: linksData.items.feedback || state.feedback,
      contact_us: linksData.items.contact_us || state.contact_us,
      faq: linksData.items.faq || state.faq,
      about_us: linksData.items.about_us || state.about_us,
      our_impact: linksData.items.our_impact || state.our_impact,
      togetherNowStamp: linksData.items.togetherNowStamp || "Brought to you by",
      togetherNowIconWhite: linksData.items.togetherNowIconWhite?.data?.attributes?.formats?.small.url || state.togetherNowIconWhite,
    }));
  }, [linksData]);

  const { data: appData, loading: appLoading } = useStrapi({
    cms: "component-app-name"
  });
  const [app, setApp] = useState("");
  useEffect(() => {
    if (appLoading) {
      setApp("MyWayfinder");
      return;
    }
    setApp((state) => appData.name || state);
  }, [appData]);

  return { app, locales, links };
};
