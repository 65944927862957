import kit from "ich-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "carbon-components-react";
import { FooterCMS } from "./cms";
import { Dropdown } from "carbon-components-react";
import { LocaleContext } from "../../store/LocaleContext";
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { backend } from "../../axios/backend.instance";
import { handleCommunicationPreference } from "../../utils";
import S from './languageDropdown.module.scss'

// SIPD-3915 | Modal for updating language preference
export const UpdateLanguageModal = ({ onConfirm, onCancel, sessionLanguage, loading }) => {
  const { links } = FooterCMS();
  return (
    <Modal
      open
      modalLabel={links.modal_heading}
      modalHeading={links.modal_content_1 + sessionLanguage + links.modal_content_2}
      primaryButtonText={links.yes_button}
      secondaryButtonText={links.no_button}
      onRequestSubmit={onConfirm}
      onRequestClose={onCancel}
      primaryButtonDisabled={loading}
      preventCloseOnClickOutside={loading}
    />
  );
};

function LanguageDropdown({ size, iconsOnly }) {
  const { user, updateUser } = useContext(kit.UserContext);
  const { locale, setLocale } = useContext(LocaleContext);
  const [fullProfile, setFullProfile] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { locales } = FooterCMS();
  const defaultEnglishLocale = {
    id: 1,
    name: "English (en)",
    icon: "US",
    code: "en",
    createdAt: "2022-02-11T16:37:40.531Z",
    updatedAt: "2022-02-11T16:37:40.531Z",
    isDefault: true
  }

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const res = await backend.get("/account/user/data", {
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`
          }
        });
        if (res?.user) {
          setFullProfile(res?.user);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Handle the case when the user change session language on setting firstname & lastname step.
        // localStorage.setItem("locale", JSON.stringify(e.selectedItem));
        // setLocale(e.selectedItem);
      }
    };

    let isMounted = true;
    if (isMounted && user.id && !user.firstLogin) {
      getUserProfile();
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  // SIPD-3915 | A reusable function to update user locale and language in the API
  const { links } = FooterCMS();
  async function updateUserLocaleAndLanguage(localeHelper, localeHelperCode, ICMSessionLanguage) {
    try {
      setUpdateLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/account/user`,
        {
          method: "put",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...fullProfile,
            givenName: fullProfile.firstName,
            familyName: fullProfile.lastName,
            housingOccupancy: fullProfile.homeOccupancy,
            dob: fullProfile.dateOfBirth ?? "",
            preferredContactMethod: fullProfile.communicationpreference ? handleCommunicationPreference(fullProfile.communicationpreference) : "email",
            address: {
              use: "Home",
              line: [fullProfile?.addressList?.address?.address1 || "", fullProfile?.addressList?.address?.address2 || ""],
              city: fullProfile?.addressList?.address?.city || "",
              state: fullProfile?.addressList?.address?.state || "",
              postalCode: fullProfile?.addressList?.address?.postalCode || "",
            },
            eContact: {
              firstname: fullProfile?.emergencyContactInfo?.firstName,
              lastname: fullProfile?.emergencyContactInfo?.lastName,
              relationship: fullProfile?.emergencyContactInfo?.relationship,
              phone: fullProfile?.emergencyContactInfo?.phone,
              email: fullProfile?.emergencyContactInfo?.email
            },
            locale: localeHelperCode,
            language: ICMSessionLanguage
          })
        }
      );
      setLocale(localeHelper);
      // We need to reload the window to load the updated preferred language in the profile page
      updateUser({ locale: localeHelperCode });
      if (!response.status === 200) {
        console.log(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  }

  const [localeHelper, setLocaleHelper] = useState();

  // SIPD-3915 | Code for Update Language Modal Start
  const [showUpdateLanguageModal, setShowUpdateLanguageModal] = useState(false);

  const openUpdateLanguageModal = () => {
    setShowUpdateLanguageModal(true);
  };

  const closeUpdateLanguageModal = () => {
    setShowUpdateLanguageModal(false);
  };

  const handleYesSetPreferred = async () => {
    const ICMSessionLanguage = localeHelper.code == "en" ? "English" : "Spanish";
    await updateUserLocaleAndLanguage(localeHelper, localeHelper.code, ICMSessionLanguage);
    closeUpdateLanguageModal();
  };

  const handleNotSetPreferred = () => {
    setLocale(localeHelper);
    closeUpdateLanguageModal();
  };

  /**
   * SIPD-3915 | Feature: Setting initial language during initial Signup - Scenario 1
   * I am a new community user in the account signup workflow
   * When I choose a different language
   * Then the session language is changed to that choice and the pages are presented in the new language choice.
   * 
   * SIPD-3915 | Setting Session Language Value - Scenario 3 & 4
   * The session language choice is different than the current profile choice
   * - Yes is selected
   * the profile language is updated to the new session language choice
   * - No is selected
   * Then the session language is updated, the profile language is not changed
   * 
   * - [localeHelper, setLocaleHelper]: Save the e.selectedItem for handleYesSetPreferred() use
   * - profilePreferredLanguage: The user profile language.
   * - ICMSessionLanguage: The session language user selected from dropdown.
   */
  const dropdownChange = async (e) => {
    setLocaleHelper(e.selectedItem);
    if (user.idToken) {
      try {
        const profilePreferredLanguage = fullProfile.language;
        const ICMSessionLanguage = e.selectedItem.code === "en" ? "English" : "Spanish";
        /**
         * SIPD-3915 | Setting Session Language Value - Scenario 3 & 4
         */
        if (profilePreferredLanguage !== "") {
          if (ICMSessionLanguage !== profilePreferredLanguage) {
            openUpdateLanguageModal();
          } else {
            localStorage.setItem("locale", JSON.stringify(e.selectedItem));
            setLocale(e.selectedItem);
          }
        } else {
          setLocale(e.selectedItem);
        }
        // Save the selected language in local storage
        localStorage.setItem("locale", JSON.stringify(e.selectedItem));
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Handle the case when the user change session language on setting firstname & lastname step.
        localStorage.setItem("locale", JSON.stringify(e.selectedItem));
        setLocale(e.selectedItem);
      }
    } else {
      /**
       * SIPD-3915 | Feature: Setting initial language during initial Signup - Scenario 1
       */
      localStorage.setItem("locale", JSON.stringify(e.selectedItem));
      setLocale(e.selectedItem);
    }

  };

  const iconMapping = (lang) => {
    switch (lang.code) {
      case 'en':
        return { ...lang, icon: 'US' }
      case 'es':
        return { ...lang, icon: 'ES' }
    }
  }

  const getSelectedIcon = () => {
    const sel = locale;
    if (sel === undefined || sel === null) {
      return defaultEnglishLocale
    }
    const withIcon = typeof sel === 'string' ? iconMapping(JSON.parse(sel)) : iconMapping(sel);
    return getUnicodeFlagIcon(withIcon.icon);
  };

  const getSelected = () => {
    const sel = locale;
    if (sel === undefined || sel === null) {
      return defaultEnglishLocale
    }
    return JSON.parse(sel);
  };

  if (iconsOnly) {
    return (
      <>
        <span className={S.languageDropdownWrapper}>
          <Dropdown
            aria-label={"Language Dropdown"}
            id="nav-icon-language-dropdown"
            direction="bottom"
            label={getUnicodeFlagIcon('US')}
            items={(locales || []).map(lang => iconMapping(lang))}
            selectedItem={getSelectedIcon()}
            itemToElement={item => item ? getUnicodeFlagIcon(item.icon) : ('')}
            onChange={dropdownChange}
            size={size || "sm"}
            data-id="nav-icon-language-dropdown"
          />
          {showUpdateLanguageModal && (
            <UpdateLanguageModal
              onConfirm={handleYesSetPreferred}
              onCancel={handleNotSetPreferred}
              sessionLanguage={localeHelper.code == "en" ? links.modal_content_English : links.modal_content_Spanish}
              loading={updateLoading}
            />
          )}
        </span>
      </>
    )
  } else {
    return (
      <>
        <span className={S.languageDropdownWrapper}>
          <Dropdown
            aria-label={"Language Dropdown"}
            id="nav-language-dropdown"
            direction="bottom"
            label={locale.name.split(" ")[0]}
            items={(locales || [])}
            selectedItem={locale}
            itemToString={(item) => {
              return item ? item.name.split(" ")[0] : "";
            }}
            onChange={dropdownChange}
            size={size || "sm"}
            data-id="nav-language-dropdown"
          />
          {showUpdateLanguageModal && (
            <UpdateLanguageModal
              onConfirm={handleYesSetPreferred}
              onCancel={handleNotSetPreferred}
              sessionLanguage={localeHelper.code == "en" ? links.modal_content_English : links.modal_content_Spanish}
              loading={updateLoading}
            />
          )}
        </span>
      </>

    )
  }
}

export default LanguageDropdown;