import axios from "axios";

export const backend = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json"
  }
});

backend.interceptors.response.use(
  (response) => {
    // Actions can be done here
    return response.data;
  },
  (error) => {
    return error;
  }
);
