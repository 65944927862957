import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  HeaderPanel,
  SwitcherItem,
  HeaderMenuItem,
} from "carbon-components-react";
import {
  User24,
  Favorite24,
  Map24,
  Logout24,
  Home24,
  Badge24,
  Activity24,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";
import LanguageDropdown from "../../LanguageDropdown";

export const UIShellContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  @media print {
    position: static;
  }
`;

export const CustomHeaderPanel = styled(HeaderPanel)`
  background: ${(props) => props.theme.colors.accent.darkBlue};
  bottom: auto;
  .bx--header-panel--expanded {
    height: 20rem;
  }
`;

export const CustomSwitcherItem = styled(SwitcherItem)`
  height: 6.5rem;
  color: #ffffff;
  .bx--switcher__item-link {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  & > a:focus {
    outline: none;
  }
  & > a:hover {
    background-color: inherit !important;
    cursor: default !important;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
`;

export const StyledButton = styled.button``;

export const StyledHeaderMenuItem = styled(HeaderMenuItem)`
  list-style-type: none;
  color: #ffffff !important;
  cursor: pointer;
  & a > span {
    color: #ffffff !important;
  }
  & > a:hover {
    background-color: ${(props) => props.theme.colors.primary.mediumBlue};
  }
`;

const Padding = styled.div`
  flex: ${(props) => props.size};
`;

const NotificationNumber = styled.div`
  flex: 1;
  font-size: 18;
`;

const NotificationContainer = styled.div`
  position: absolute;
  top: 2px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 30px;
  width: ${(props) => {
    if (props.width > 999) {
      return "40px";
    } else if (props.width > 99) {
      return "30px";
    } else {
      return "20px";
    }
  }};
  height: ${(props) => {
    if (props.width > 999) {
      return "28px";
    } else if (props.width > 99) {
      return "24px";
    } else {
      return "20px";
    }
  }};
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const NewNotification = (props) => (
  <NotificationContainer width={props.quantity}>
    <Padding size={1} />
    <NotificationNumber>{props.quantity}</NotificationNumber>
    <Padding size={1} />
  </NotificationContainer>
);

const MobileNavContainer = styled.div`
  // background: #216682;
  background-color: ${(props) => props.theme.colors.accent.darkBlue};
  height: 100%;
  overflow: auto;
  padding-top: 1rem;
`;

const NavLink = styled.div`
  height: 2.5em;
  padding-left: 1em;
  cursor: pointer;
  color: #f2f4f8;
  font-weight: 300px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
  }

  & div {
    display: inline-flex;
  }

  &:active {
    background-color: #343a3f;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.mediumBlue};
  }
`;

const StyledNavLink = styled(NavLink)`
  margin-top: 3rem;
`;

export const MobileNav = ({
  history,
  loggedIn,
  close,
  logout,
  open,
  navBarData,
  broadcastLogOut,
}) => {
  const navigate = (route) => {
    if (route === "" && loggedIn) {
      history.push("/");
    } else if (route === "") {
      history.push("/home");
    } else {
      history.push(route);
    }
    close();
  };

  return (
    <MobileNavContainer>
      {loggedIn ? (
        <>
          <NavLink
            onClick={() => navigate("/")}
            aria-label={navBarData.home}
            data-id="nav-home-btn"
          >
            <Home24 />
            {navBarData?.home}
          </NavLink>
          <NavLink
            onClick={() => navigate("/360Dashboard")}
            aria-label={navBarData.dashboard}
            data-id="nav-dashboard-btn"
          >
            <Favorite24 />
            {navBarData.dashboard}
          </NavLink>
          <NavLink
            onClick={() => navigate("/service-directory")}
            aria-label={navBarData.service_directory}
            data-id="nav-service-directory-btn"
          >
            <Map24 />
            {navBarData.service_directory}
          </NavLink>
          <NavLink
            onClick={() => navigate("/system-stats")}
            aria-label={navBarData.our_impact}
            data-id="nav-impact-btn"
          >
            <Activity24 />
            {navBarData.our_impact}
          </NavLink>
          <NavLink
            onClick={() => navigate("/account")}
            aria-label={navBarData.profile}
            data-id="nav-profile-btn"
          >
            <User24 />
            {navBarData.profile}
          </NavLink>
          <NavLink
            onClick={() => navigate("/account/badges")}
            aria-label="NavigateBadges"
            data-id="nav-badges-btn"
          >
            <Badge24 />
            {navBarData.badges}
          </NavLink>
          <NavLink
            onClick={() => {
              logout();
              broadcastLogOut();
            }}
            aria-label={navBarData.logout}
          >
            <Logout24 />
            {navBarData.logout}
          </NavLink>
          {/* <StyledNavLink
            aria-label="Show Watson Assistant"
            onClick={() => {
              if (window.WebChatInstance) {
                window.WebChatInstance.openWindow();
              }
              close();
            }}
          >
            {navBarData.need_help_chatbot}
          </StyledNavLink> */}
          <div style={{ width: "fit-content", margin: "0 1rem" }}>
              <LanguageDropdown />
          </div>
        </>
      ) : (
        <>
          <NavLink
            onClick={() => navigate("/")}
            aria-label={navBarData?.home}
            data-id="nav-home-btn"
          >
            <Home24 />
            {navBarData?.home}
          </NavLink>
          <NavLink
            onClick={() => navigate("/login")}
            aria-label="Login/Signup"
            data-id="nav-login-signup-btn"
          >
            <User24 />
            {navBarData.login_signup}
          </NavLink>
          <NavLink
            aria-label={navBarData.service_directory}
            onClick={() => navigate("/service-directory")}
          >
            <Map24 />
            {navBarData.service_directory}
          </NavLink>
          <NavLink
            onClick={() => navigate("/system-stats")}
            aria-label={navBarData.our_impact}
          >
            <Activity24 />
            {navBarData.our_impact}
          </NavLink>
          {/* <StyledNavLink
            aria-label="Watson Assistant"
            onClick={() => {
              if (window.WebChatInstance) {
                window.WebChatInstance.openWindow();
              }
              close();
            }}
          >
            {navBarData.need_help_chatbot}
          </StyledNavLink> 
          */}
          <div style={{ width: "fit-content", margin: "0 1rem" }}>
            <LanguageDropdown />
          </div>
        </>
      )}
    </MobileNavContainer>
  );
};
