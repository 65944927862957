import React, { useState } from "react";

export const NavContext = React.createContext({});

export const NavProvider = ({ children }) => {
  const [backButton, setBackButton] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [profileImageChange, setProfileImageChange] = useState(false);
  const [userNameChange, setUserNameChange] = useState(false);
  return (
    <NavContext.Provider
      value={{
        backButton,
        setBackButton,
        mobileNavOpen,
        setMobileNavOpen,
        profileImageChange,
        setProfileImageChange,
        userNameChange,
        setUserNameChange
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const NavConsumer = NavContext.Consumer;
