import {
  Header as Hr,
  HeaderNavigation as HrN,
  SwitcherItem as SI
} from "carbon-components-react";
import styled from "styled-components";

export const DropdownMenuListItem = styled.li`
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: 0.1s;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.mediumBlue} !important;
  }
`;

export const DropdownMenuContainer = styled.div`
  background: ${(props) => props.theme.colors.primary.darkBlue} !important;
  position: absolute;
  margin-top: 0.5rem;
  left: 0;
  padding: 0.5rem 0;
  z-index: -1;
`;

export const Header = styled(Hr)`
  background: ${(props) => props.theme.colors.primary.darkBlue} !important;

`;

export const HeaderNavigation = styled(HrN)`
  background: ${(props) => props.theme.colors.primary.darkBlue};
  & a {
    font-size: 1rem;
  }
`;

export const SwitcherItem = styled(SI)`
  color: #ffffff;
  & > a:hover {
    background-color: ${(props) =>
      props.theme.colors.accent.mediumBlue} !important;
  }
`;

export const ProfileImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 2px solid #fff;
  border-radius: 20%;
  overflow: auto;
  @media screen and (max-width: 670px) {
    width: 6rem;
    height: 6rem;
  }

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > svg {
    width: 85%;
    height: auto;
    border: 1px solid #0f62fe;
    border-radius: 50%;
    fill: white;
  }
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
