import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  align-items: center;
  // min-height: 100vh;
`;

export const WavesImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0px;
  @media (max-width: 500px) {
    height: 50%;
    width: auto;
  }
  @media (max-width: 500px) {
    height: 50%;
    width: auto;
  }
`
export const FishImage = styled.img`
  height: 30px;
  @media ${(props) => props.theme.breakpoints.xs} {
    height: 40px;
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    height: 40px;
  }
  @media ${(props) => props.theme.breakpoints.md} {
    height: 50px;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    height: 60px;
  }
`

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  background-size: cover;
  background-position: center center;
  display: flex;
  z-index: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 100px) {
    justify-content: start;
    padding-bottom: 20vh;
  }
  @media (min-width: 500px) {
    justify-content: start;
    padding-bottom: 20vh;
  }
  @media (min-width: 660px) {
    justify-content: start;
    padding-top: 20vh;
  }
  @media (min-width: 1260px) {
    justify-content: center;
    padding-top: 20vh;
  }
  @media (min-width: 1500px, max-width: 15000px) {
    justify-content: center;
    padding-top: 0;
  }
  h1 {
    font-size: 60px;
    @media (min-width: 300px) {
      font-size: 60px;
    }
    @media (min-width: 600px) {
      font-size: 90px;
    }
    @media (min-width: 700px) {
      font-size: 90px;
    }
    @media (min-width: 1060px) {
      font-size: 90px;
    }
    @media (min-width: 1300) {
      font-size: 90px;
    }
  }
  h2 {
    font-size: 14px;
    color: black;
    @media (min-width: 300px) {
      font-size: 20px;
    }
    @media (min-width: 600px) {
      font-size: 20px;
    }
    @media (min-width: 700px) {
      ont-size: 20px;
    }
    @media (min-width: 1060px) {
      ont-size: 20px;
    }
    @media (min-width: 1300) {
      ont-size: 30px;
    }
  }
`;
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
`;
export const Heading = styled.h1`
  position: absolute;
  font-size: clamp(2.5rem, 6vw, 4.5rem);
  font-weight: 700;
  color: #ffffff;
  z-index: 2;
`;

export const Items = styled.div`
  position: relative;
  height: auto;
  margin: 0 auto;
  width: 100%;
  @media (min-width: 1280px) {
    width: 1000px;
    margin: 5rem auto;
  }
`;
