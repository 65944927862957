import React, { useState, useEffect, useContext } from 'react';
import kit from 'ich-ui-kit';

export const BusStopsContext = React.createContext({});

export const BusStopsProvider = ({ children }) => {
  const [busStops, setBusStops] = useState([]);
  const { apiService } = useContext(kit.ApiContext);

  useEffect(() => {
    getBusStops();
  }, []);

  const getBusStops = async () => {
    try {
      const data = await apiService.get('/providers/busstops');
      if (data?.stops && data?.stops?.length > 0) {
        setBusStops(data?.stops);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BusStopsContext.Provider value={{ busStops }}>
      <>{children}</>
    </BusStopsContext.Provider>
  );
};

export const BusStopsConsumer = BusStopsContext.Consumer;
