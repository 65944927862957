import { Tooltip } from "carbon-components-react";
import { CheckmarkOutline16 } from "@carbon/icons-react";
import { useValidationCMS } from "./validationCMS";
const TipLine = ({ message, valid }) => {
  return (
    <div style={{ display: "flex" }}>
      <CheckmarkOutline16
        style={
          !valid
            ? { marginRight: "0.5rem", fill: "green" }
            : { marginRight: "0.5rem" }
        }
      />
      <p>{message}</p>
    </div>
  );
};

export const CustomToolTip = ({ errors, open, showIcon, align, direction }) => {
  const { validations } = useValidationCMS();
  return (
    <>
      <Tooltip
        open={open}
        showIcon={showIcon}
        align={align || "end"}
        direction={direction}
      >
        <TipLine
          key={1}
          valid={errors.includes("count")}
          message={validations.required_num_characters ?? "10-31 characters"}
        />
        <TipLine
          key={2}
          valid={errors.includes("upper")}
          message={validations.required_uppercase ?? "An uppercase letter"}
        />
        <TipLine
          key={3}
          valid={errors.includes("lower")}
          message={validations.required_lowercase ?? "A lowercase letter"}
        />
        <TipLine
          key={4}
          valid={errors.includes("number")}
          message={validations.required_number ?? "A Number"}
        />
        <TipLine
          key={5}
          valid={errors.includes("symbol")}
          message={
            validations.required_valid_symbol ?? `One valid symbol: #?!@$%^&*-`
          }
        />
        <TipLine
          key={6}
          valid={errors.includes("nospace")}
          message={
            validations.required_no_spaces_special ??
            `No spaces or other special chars`
          }
        />
      </Tooltip>
    </>
  );
};
