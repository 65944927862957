import DOMPurify from "dompurify";

export const fetchWithRetry = async (
  url,
  options = {},
  retries = 3,
  backoff = 300
) => {
  try {
    const res = await fetch(url, options);

    if (res.ok) return res;

    if (retries > 0 && res.status === 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(fetchWithRetry(url, options, retries - 1, backoff * 2));
        }, backoff);
      });
    }
    throw new Error(res);
  } catch (err) {
    return err;
  }
};

export const determineAssessmentSummaryColor = (color) => {
  switch (color) {
    case "GREEN":
      return "#95c93d";
    case "LIGHT_GREEN":
      return "#4ac1e0";
    case "YELLOW":
      return "#f4db5f";
    case "ORANGE":
      return "#faa41a";
    case "RED":
      return "#ec5c29";
    default:
      return "#c6c6c6";
  }
  // switch(color) {
  //   case 'GREEN':
  //   return '#24a147';
  //   case 'LIGHT_GREEN':
  //     return '#6fdb8b';
  //   case 'YELLOW':
  //     return '#f2c21b';
  //   case 'ORANGE':
  //     return '#ff832b';
  //   case 'RED':
  //     return '#da1e27';
  //   default:
  //     return '#d8d8d8';
  // };
};

export const shouldAutoComplete = "off";

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getNewBadge = async (user, badgeType) => {
  if (user.id) {
    try {
      await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/badges",
        {
          method: "post",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${user.accessToken} ${user.idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: badgeType,
          }),
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
};

export const parseHtml = (_text) => {
  if (_text) {
    let result = _text.replace(/;/gi, ' ');
    result = result.split('*').join(' ');
    result = new DOMParser().parseFromString(result, "text/html");
    result = result.body.textContent;
    return result;
  }
  return "";
};

export const displayAddress = (pro) => {
  let address = pro.address || "";
  let city = pro.city || "";
  let state = pro.state || "";
  let zip = pro.zip || "";
  if (!address.length && !city.length && !state.length && !zip.length) {
    return "No information available";
  } else if (
    !(!address.length && !city.length && !state.length && zip.length) &&
    address.length
  ) {
    return `${address}, ${city} ${state} ${zip}`;
  } else if (
    !address.length &&
    !(!address.length && !city.length && !state.length && zip.length)
  ) {
    return `${city}, ${state} ${zip}`;
  }
  return "Area postal code: "`${zip}`;
};

export const formatUrl = (url) => {
  let provURL = url ? url.toLowerCase() : null;

  if (provURL && provURL.indexOf(".") > 0 && provURL.indexOf(".") < 4) {
    provURL = "https://" + provURL;
  }
  return url;
};

export const parseToArray = (str) => {
  return str.split("|");
};

export const formatDate = (str) => {
  let x = str.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

  return !x[2] ? x[1] : x[1] + "/" + x[2] + (x[3] ? "/" + x[3] : "");
};

// Parse service's domains
export const cleanDomains = (input) => {
  let arr = input
    .map((domain) => domain.split(":")[0])
    .filter((el) => el !== "");
  arr = arr.map((domain) => {
    domain = domain[0].toUpperCase() + domain.slice(1);
    if (domain.includes(" ")) {
      let words = domain.split(" ");
      domain = words[0] + " " + words[1][0].toUpperCase() + words[1].slice(1);
    }
    return domain;
  });
  return arr;
};

export const sortByCapacity = (providers) => {
  // assumes providers are already sorted by ORG_REG
  let capacity = [],
    green = [],
    yellow = [],
    unknown = [],
    red = [];
  let count = 0;
  while (
    providers[count] &&
    (providers[count].props.provider.orgReg ||
      providers[count].props.provider.orgReg === "1")
  ) {
    if (providers[count].props.provider.capacity === "GREEN") {
      green.push(providers[count]);
    } else if (providers[count].props.provider.capacity === "YELLOW") {
      yellow.push(providers[count]);
    } else if (providers[count].props.provider.capacity === "RED") {
      red.push(providers[count]);
    } else {
      unknown.push(providers[count]);
    }
    count++;
  }
  capacity.push(green);
  capacity.push(yellow);

  capacity.push(red);
  capacity.push(unknown);
  capacity = capacity.flat();
  providers = providers.slice(count);
  providers = capacity.concat(providers);
  return providers;
};

export function monthDifferenceInTimstamps(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const getLocalTime = (referralsArray) => {
  referralsArray = referralsArray.map((ref) => {
    let authoredLocalTime;
    if (ref.authoredOn?.includes("T")) {
      authoredLocalTime = ref.authoredOn;
    } else {
      authoredLocalTime = ref.authoredOn?.replace(" ", "T") + "Z";
    }
    authoredLocalTime = new Date(authoredLocalTime)?.toLocaleDateString()
    return ({...ref, authoredOn: authoredLocalTime})
  })
  referralsArray = referralsArray?.sort((a,b) => new Date(b.authoredOn) - new Date(a.authoredOn))
  return referralsArray;
}

const uppercaseFirstLetter = (string) => {
  const words = string.split(" ").map((word, i) => {
    if(!word) return "";
    let [first, ...rest] = word.split("");
    return first.toUpperCase() + rest.join("");
  });
  return words.join(" ");
};

export const getDisplayedStatus = (incomingStatus, requestType) => {
  try {
    let status = incomingStatus.toLowerCase();
    if (requestType !== null && requestType !== undefined && requestType !== "") {
      let type = requestType.toLowerCase();
      if (status === "pending" && type === "assistance request") {
        return "New Request";
      }
    }

    if((status.includes("needs request") || status.includes("pending")) && requestType === "Email") {
      return "Pending";
    } else if (status.includes("working") || status.includes("reopen")) {
      return "In Progress";
    } else if (status.includes("waiting for client")) {
      return "Waiting for You"  
    } else if (status.includes("waiting for provider")) {
      return "Waiting for Provider"  
    } else if ((status.includes("close ") && status.includes("client")) || (status.includes("close ") && status.includes("rejected"))) {
      return "Closed - Canceled by You";
    } else if (status.includes("closure") || status.includes("close ")) {
      return uppercaseFirstLetter(incomingStatus.split(":")[0]);
    } else if (status === "pending"  || status === "about to expire - pending") {
      return "New Referral";
    } else if (status.includes("rejected by receiver")) {
      return "Provider Cannot Accept";
    } else if (status.includes("rejected by")) {
      return "Canceled by You";
    } else if (status.includes("closed")) {
      if (status.includes("unable to resolve")) {
        return "Closed - Unresolved"
      } else {
        return uppercaseFirstLetter(incomingStatus.split(":")[0]);
      }
    } else if (status.includes("cancel")) {
      return "Provider Cannot Accept"
    } else {
      return uppercaseFirstLetter(incomingStatus);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getDisplayedStatusStats = (incomingStatus="", cms, requestType) => {
  if(!cms) {
    return incomingStatus;
  }
  try {
    let status = incomingStatus.toLowerCase();
    if (requestType !== null && requestType !== undefined && requestType !== "") {
      let type = requestType.toLowerCase();
      if (status === "pending" && type === "assistance request") {
        return cms.status_new_request;
      }
    }
    if (status.includes("working") || status.includes("reopen") || status.includes("active")) {
      return cms.status_in_progress;
    } else if (status.includes("waiting for client") || status.includes("accepted by receiver")) {
      return cms.status_waiting_for_client;
    } else if (status.includes("waiting for provider") || status.includes("accepted by referee")) {
      return cms.status_waiting_for_provider;
    } else if ((status.includes("close ") && status.includes("client")) || (status.includes("close ") && status.includes("rejected"))) {
      return cms.status_closed;
    } else if (status.includes("closure") || status.includes("close ")) {
      return cms.status_pending_closure;
    } else if (status === "pending"  || status === "about to expire - pending") {
      return cms.status_new_referral;
    } else if (status.includes("rejected by receiver")) {
      return cms.status_provider_cannot_accept;
    } else if (status.includes("rejected by")) {
      return cms.status_canceled_by_client;
    } else if (status.includes("closed")) {
      return cms.status_closed;
    } else if (status.includes("cancel")) {
      return cms.status_provider_cannot_accept;
    } else if (status.includes("referral expired")) {
      return cms.status_referral_expired;
    } else if (status.includes("needs request")) {
      return cms.status_needs_request;
    } else if (status.includes("new request")) {
      return cms.status_new_request;
    } else {
      return incomingStatus;
    }
  } catch (e) {
    console.log(e);
  }
};


// cms endpoint: 'list-referral-status'
export const getDisplayedReferralStats = (incomingStatus= "", cms, requestType) => {
  if(!cms) {
    return incomingStatus;
  }
    try {
      let status = incomingStatus.toLowerCase();
      if (requestType !== null && requestType !== undefined && requestType !== "") {
        let type = requestType.toLowerCase();
        if (status === "pending" && type === "assistance request") {
          return cms.new_request;
        }
      }
      if((status.includes("needs request") || status.includes("pending")) && requestType === "Email") {
        return cms.pending;
      } else  if (status.includes("never responded") && requestType === "Email") {
        return cms.never_responded;
      } else  if (status.includes("provider responded") && requestType === "Email") {
        return cms.provider_responded;
      } else  if (status.includes("working") || status.includes("reopen") || status.includes("active")) {
        return cms.working;
      } else if (status.includes("waiting for client") || status.includes("accepted by receiver")) {
        return cms.waiting_for_client;
      } else if (status.includes("waiting for provider") || status.includes("accepted by referee")) {
        return cms.waiting_for_provider;
      } else if ((status.includes("close ") && status.includes("client")) || (status.includes("close ") && status.includes("rejected"))) {
        return cms.closed_client_rejected;
      } else if (status.includes("closure") || status.includes("close ")) {
        // SIPD-4054 | Revised: The bug is caused because 'cms.pending' originally does not have the correct label, 
        // and there is a lack of logic to verify the reason for closure.
        if (status.includes("resolved")){
          return cms.pending_closure_resolved;
        } else if (status.includes("unable to contact")) {
          return cms.pending_closure_unable_to_contact;
        } else if (status.includes("unable to resolve")) {
          return cms.pending_closure_unable_to_resolve;
        } else if (status.includes("cancel")) {
          return cms.pending_closure_cancel;
        }
        return cms.pending_closure;
      } else if (status === "pending"  || status === "about to expire - pending") {
        return cms.new_referral;
      } else if (status.includes("rejected by receiver")) {
        return cms.rejected_by_receiver;
      } else if (status.includes("rejected by")) {
        return cms.rejected_by_referee;
      } else if (status.includes("closed")) {
        // Revised: The initial logic for handling 'closed' status is missing a check for the specific closure reason,
        // which is now addressed using if statements for various scenarios.
        if (status.includes("resolved")){
          return cms.closed_resolved;
        } else if (status.includes("unable to resolve")) {
          return cms.closed_unable_to_resolved;
        } else if (status.includes("unable to contact")) {
          return cms.closed_unable_to_contact;
        } else if (status.includes("cancelled") || status.includes("canceled")) {
          return cms.closed_cancelled;
        }
        return cms.closed;
      } else if (status.includes("cancel")) {
        return cms.rejected_by_receiver;
      } else if (status.includes("referral expired")) {
        return cms.referral_expired;
      } else if (status.includes("needs request")) {
        return cms.needs_request;
      } else if (status.includes("new request")) {
        return cms.new_request;
      } else if (status.includes("completed")) {
        return cms.completed;
      } else {
        return uppercaseFirstLetter(incomingStatus);
      }
    } catch (e) {
      console.log(e);
    }
};

export const getDescriptiveHistory = (historyItem, referral, cms) => {
  try {
    let description = historyItem.description;
    let newd = "";
    if (description.includes("Referee")) {
      newd = description.replace("Referee", referral.referree);
    } else if (description.includes("Client")) {
      newd = description.replace("Client", referral.referree);
    } else if (description.includes("Referrer")) {
      newd = description.replace("Referrer", referral.requester);
    } else if (description.includes("Close ")) {
      newd = description.replace("Close", "Closed");
    } else if (
      description.includes("Waiting for Provider") || description.includes("Accepted by Provider")
    ) {
      newd = description;
    } else if (
      description.includes("Provider") &&
      historyItem.author &&
      historyItem.author !== "username"
    ) {
      newd = description.replace("Provider", historyItem.author);
    } else if (description.includes("reassigned")) {
      newd = cms.history_text_reassigned.replace("[referralId]", referral.id);
    } else {
      newd = description;
    }
    if (newd.includes("Status of")) {
      let status = newd.split("is now ")[1];
      let newStatus;
      if (status.includes("About to Expire")) {
        newStatus = cms.history_text_about_to_expire;
      } else {
        newStatus = getDisplayedStatusStats(status, cms);

      }
      newd = newd.replace(status, newStatus);
    }

    if(newd.includes("Referral")) {
      newd = newd.replace("Referral", cms.text_referral)
    }

    return newd;
  } catch (e) {
    console.log(e)
  }
};

export const convertTimestamp = (timestamp) => {
  try {
    let authoredLocalTime = timestamp
    if (timestamp?.includes("T")) {
      authoredLocalTime = timestamp
    } else {
      authoredLocalTime = timestamp?.replace(" ", "T") + "Z";
    }
    authoredLocalTime = new Date(authoredLocalTime).toLocaleString();
    return authoredLocalTime;
  } catch (e) {
    console.log(e)
    return "";
  }
};

export const convertTimestamp10Days = (timestamp) => {
  try {
    let authoredLocalTime = timestamp
    if (timestamp?.includes("T")) {
      authoredLocalTime = timestamp
    } else {
      authoredLocalTime = timestamp?.replace(" ", "T") + "Z";
    }
    authoredLocalTime = new Date(authoredLocalTime);
    authoredLocalTime.setDate(authoredLocalTime.getDate() + 10);
    return authoredLocalTime.toLocaleDateString();
  } catch (e) {
    console.log(e)
    return "";
  }
};

export const parseHiddenText = (txt) => {
  // 211 has some hidden text to enhance their searching.
  // Removing that text here.
  return txt?.replace(/=(.*)/gm, "");
};

export const removeHtml = (_text) => {
  if (_text !== null && _text !== undefined) {
    let res = new DOMParser().parseFromString(_text, "text/html");
    res = res.body.textContent;
    return res;
  }
  return _text;
};

export const handlePhoneChange = (e, setState, name) => {
  const x = e.target.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  setState((state) => ({
    ...state,
    [name]: (e.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : ""))
  }));
};

export const formatProviderAddress = (provider, confidential_address_text) => {
  if (!provider.loc_confidential_address) {
    const tempProvAddress = provider.address ? provider.address + ", " : "";
    const tempProvCity = provider.city ? provider.city + ", " : "";
    const tempProvState = provider.state ? provider.state + ", " : "";
    const tempProvZip = provider.zip ? provider.zip : "";
    return tempProvAddress + tempProvCity + tempProvState + tempProvZip
  } else {
    return confidential_address_text;
  }
}

export const colorBlindLegend = (status) => {
  if (status) {
    switch (status.toLowerCase()) {
      case "not ok":
      case "not okay":
        return <>1</>;
      case "ok":
      case "okay":
        return <>2</>;
      case "good":
        return <>3</>;
      case "great":
        return <>4</>;
      case "excellent":
        return <>5</>;
      default:
        return <></>;
    }
  }
};

export const parseFeedbackBadges = (text, fallback, translator, cms={}) => {  
  if (text && text.includes(',')) {
    const list = text.split(',');
    return (
      <ul>
        {list.map((l) => (<li style={{padding: '0.5rem'}}>
          {typeof translator === 'function' ? translator(l, cms) : l}
        </li>))}
      </ul>
    )
  } else {
    return <p>{text || fallback}</p>
  }
};

export const isNoteInputTooLong = (text, max) => {
  if (text?.length && text?.length > max) {
    return true;
  } else {
    return false;
  }
}

export const truncateText = (str, charLimit) => {
  if (str && !isNaN(charLimit) && str.length > charLimit) {
    // Find the last space within the limit
    const lastSpace = str.lastIndexOf(' ', charLimit);
    if (lastSpace === -1) {
      return str.substring(0, charLimit) + '...';
    }
    // Truncate at the last whole word
    return str.substring(0, lastSpace) + '...';
  }
  return str;
}

export const constructOrgOverviewUrl = (orgId, orgSource) => {
  const url = `/provider-information?id=${orgId}&orgSource=${orgSource || "211"}`
  if (validateUrl(url)) return url;
  return '';
};

export const constructServiceOverviewUrl = (serviceAtLocationId, orgSource) => {
  const url = `/service-information?id=${serviceAtLocationId}&orgSource=${orgSource || "211"}`
  if (validateUrl(url)) return url;
  return '';
};

export const generateClickToCallLink = (phoneNumber) => {
  try {
    if (!phoneNumber) return '';

    // cleaning and standardizing phone number
    phoneNumber = phoneNumber?.toLowerCase();
    phoneNumber = phoneNumber?.replaceAll(' ', '');
    phoneNumber = phoneNumber?.replaceAll('.', '');
    phoneNumber = phoneNumber?.replaceAll('(', '');
    phoneNumber = phoneNumber?.replaceAll(')', '-');
    phoneNumber = phoneNumber?.replaceAll(/(?=x\d{1})x/gm, 'ext');
    phoneNumber = phoneNumber?.replaceAll('-', '');

    const [baseNumber, extension] = phoneNumber?.split('ext');

    let link = '';
    if (
      /^\d{3}$/gm.test(baseNumber) ||
      /^\d{7}$/gm.test(baseNumber) ||
      /^\d{10}$/gm.test(baseNumber)
    ) {
      link = `tel:${baseNumber}`;
    } else if (/^\d{11}$/gm.test(baseNumber)) {
      link = `tel:+${baseNumber}`;
    } else {
      return '';
    }

    if (extension) {
      link += `,${extension}`;
    }
    return link;
  } catch (e) {
    return '';
  }
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = (componentImport, name) => {
  return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
      );
      // try to import the component
      componentImport().then((component) => {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) { // not been refreshed yet
              window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
      });
  });
};



/* == CMS TRANSLATIONS ======================================= */ 

export const translateWellBeingStatus = (wellBeingCMS, incomingStatus="") => {
  if(!wellBeingCMS) {
    return incomingStatus;
  }
  const status = incomingStatus?.toLowerCase();
  switch (status) {
    case "excellent":
      return wellBeingCMS.excellent;
    case "great":
      return wellBeingCMS.great;
    case "good":
      return wellBeingCMS.good;
    case "okay":
      return wellBeingCMS.okay;
    case "not okay":
      return wellBeingCMS.not_okay;
    default:
      return incomingStatus;
  }
};


export const translateDomains = (domainCMS, incomingName="") => {
  if(!domainCMS) {
    return incomingName;
  }
  const name = incomingName?.toLowerCase();
  switch (name) {
    case "health":
      return domainCMS.health;
    case "food":
      return domainCMS.food;
    case "employment":
      return domainCMS.employment;
    case "income":
      return domainCMS.income;
    case "education":
      return domainCMS.education;
    case "transportation":
      return domainCMS.transportation;
    case "social connections":
      return domainCMS.social_connections;
    case "safety":
      return domainCMS.safety;
    case "financial":
      return domainCMS.financial;
    case "dependent care":
      return domainCMS.dependent_care;
    case "housing":
      return domainCMS.housing;
    case "other":
      return domainCMS.other;
    default:
      return incomingName;
  }
};

export const translateInviteStatus = (cms, incomingStatus="") => {
  if(!cms) {
    return incomingStatus;
  }
  const status = incomingStatus?.toLowerCase();
  switch (status) {
    case "invited":
      return cms.status_invited;
    case "added":
      return cms.status_added;
    default:
      return incomingStatus;
  }
};

export const translateRelation = (cms, relation="") => {
  if(!cms) {
    return relation;
  }
  switch (relation) {
    case "Significant Other":
      return cms.relation_significant_other;
    case "Spouse":
      return cms.relation_spouse;
    case "Former Spouse":
      return cms.relation_former_spouse;
    case "Sibling":
      return cms.relation_sibling;
    case "Dependent":
      return cms.relation_dependent;
    case "Extended Family Member":
      return cms.relation_extended_family;
    case "Parent":
      return cms.relation_parent;
    case "Neighbor":
      return cms.relation_neighbor;
    case "Roommate":
      return cms.relation_roommate;
    case "Friend":
      return cms.relation_unrelated_friend;
    case "Representative Payee":
      return cms.relation_rep_payee;
    case "Guardian":
      return cms.relation_guardian;
    case "Conservator":
      return cms.relation_conservator;
    case "Power of Attorney":
      return cms.relation_attorney;
    case "Ward of Court":
      return cms.relation_ward_of_court;
    case "Other":
      return cms.relation_other;
    default:
      return relation;
  }
}


export const translateBadgeCaptions = (cms, text="") => {
  if(!cms) {
    return text;
  }
  switch (text) {
    case "Accepted a Referral":
      return cms.accepted_referral.text;
    case "Building a Care Team":
      return cms.building_care_team.text;
    case "Check-In":
    case "Check In":
      return cms.check_in.text;
    case "Completed Profile":
      return cms.completed_profile.text;
    case "Invite a Friend":
      return cms.invite_friend.text;
    case "Needs Request":
      return cms.needs_request.text;
    case "New Member":
      return cms.new_member.text;
    case "Completed Referral Feedback":
      return cms.referral_feedback.text;
    default:
      return text;
  }
};

export const translateBadgeTooltips= (cms, text="") => {
  if(!cms) {
    return {
      tooltipText: text,
      tooltipRedirect: "",
    };  
  }
  switch (text) {
    case "accepted-referral":
      return {
        tooltipText: cms.accepted_referral.tooltipText,
        tooltipRedirect: cms.accepted_referral.tooltipRedirect,
      };
    case "care-team":
      return {
        tooltipText: cms.building_care_team.tooltipText,
        tooltipRedirect: cms.building_care_team.tooltipRedirect,
      };
    case "check-in":
      return {
        tooltipText: cms.check_in.tooltipText,
        tooltipRedirect: cms.check_in.tooltipRedirect,
      };
    case "completed-profile":
      return {
        tooltipText: cms.completed_profile.tooltipText,
        tooltipRedirect: cms.completed_profile.tooltipRedirect,
      };
    case "invite-a-friend":
      return {
        tooltipText: cms.invite_friend.tooltipText,
        tooltipRedirect: cms.invite_friend.tooltipRedirect,
      };
    case "needs-request":
      return {
        tooltipText: cms.needs_request.tooltipText,
        tooltipRedirect: cms.needs_request.tooltipRedirect,
      };
    case "new-member":
      return {
        tooltipText: cms.new_member.tooltipText,
        tooltipRedirect: cms.new_member.tooltipRedirect,
      };
    case "referral-feedback":
      return {
        tooltipText: cms.referral_feedback.tooltipText,
        tooltipRedirect: cms.referral_feedback.tooltipRedirect,
      };
    default:
      return {
        tooltipText: text,
        tooltipRedirect: "",
      };
  }
};

// "list-referral-status" strapi endpoint
export const translateRequestType = (incomingType="", cms) => {
  if(!cms) {
    return incomingType;
  }
  let type = incomingType.toLowerCase();
  switch (type) {
    case "email":
      return cms.email;
    case "assistance request":
      return cms.assistance_request;
    case "needs request": 
      return cms.needs_request;
    default:
      return incomingType;
  }
};

// pass needs_request or cn_request status_dropdown from page-client-dashboard endpoint
export const translateNeedsRequest= (incomingStatus="", cmsStatusDropdown) => {
  if(!cmsStatusDropdown) {
    return incomingStatus;
  }
  let status = incomingStatus.toLowerCase();
  switch (status) {
    case "pending":
      return cmsStatusDropdown[0].text;
    case "provider responded":
      return cmsStatusDropdown[1].text;
    case "never responded": 
      return cmsStatusDropdown[2].text;
    case "request not sent":
      return cmsStatusDropdown[3].text;
    default:
      return incomingStatus;
  }
};

export const translateSurveyResponses = (response="", cms) => {
  if(!cms) {
    return response;
  }
  response=response.trim();
  switch (response) {
    case "Yes" :
      return cms.q1_btn_yes;
    case  "No" :
      return cms.q1_btn_no;
    case  "If no, what else can we help you with?":
      return cms.q1_label_no;
    case  "Tell us how we can improve your experience":
      return cms.q1_placeholder_no;
    case  "Select difficulty...":
      return cms.q2_label_difficulty;
    case  "Very Difficult":
      return cms.q2_very_difficult;
    case  "Difficult" :
      return cms.q2_difficult;
    case  "Neutral" :
      return cms.q2_neutral;
    case  "Easy" :
      return cms.q2_easy;
    case  "Very Easy" :
      return cms.q2_very_easy;
    case  "Select likelihood...":
      return cms.q3_label_likelihood;
    case  "Very Likely" :
      return cms.q3_text_very_likely;
    case  "Very Unlikely" :
      return cms.q3_text_very_unlikely;
    case  "Multi-lingual":
      return cms.q6_multi;
    case  "Quick Responder":
      return cms.q6_quick;
    case  "American Sign Language":
      return cms.q6_asl;
    case  "Blind and Visually Impaired":
      return cms.q6_blind;
    case  "LGBTQ+ Ally":
      return cms.q6_lgbtq;
    case  "Childcare Available":
      return cms.q6_childcare;
    case  "Culturally Competent: LatinX":
      return cms.q6_latinx;
    case  "Culturally Competent: New Americans":
      return cms.q6_newam;
    case  "Culturally Competent: Black/African American":
      return cms.q6_afam;
    case  "Family Friendly":
      return cms.q6_family;
    case  "Sensory Friendly":
      return cms.q6_sensory;
    case  "Trauma-Informed":
      return cms.q6_trauma;
    case  "Mobility Accessible":
      return cms.q6_mobility;
    case  "Tell us abut your experience":
      return cms.q7_tell_us;
    case  "Would you like to nominate [provider_name] for a badge based on their special skills?":
      return cms.q7;
    case  "Select Special skills":
      return cms.q6_label_skills;
    case  "Select..." :
      return cms.q6_label_select;
    case  "Somewhat Difficult" :
      return cms.q2_somewhat_difficult;
    case  "Somewhat Easy" :
      return cms.q2_somewhat_easy;
    default:
      return response;
  }
};

export const translateEventName = (incomingEventName="", cms) => {
  if(!cms) {
    return incomingEventName;
  }
  let eventName = incomingEventName.toLowerCase();
  switch (eventName) {
    case "login" :
      return cms.text_login;
    case  "logout" :
      return cms.text_logout;
    case  "account management":
      return cms.text_account_management;
    case  "dashboard viewed":
      return cms.text_dashboard_viewed;
    case  "new check-in":
      return cms.text_new_checkin;
    case  "needs requests":
      return cms.text_needs_requests;
    case  "care team requests":
      return cms.text_care_team_requests;
    case  "badges":
      return cms.text_badges;
    case  "referral":
      return cms.text_referral;
    default:
      return incomingEventName;
  }

}


// 'section-demographic' cms
export const translateHousingSituation= (option="", cms) => {
  if(!cms) {
    return option;
  }
  option=option.trim();
  switch (option) {
    case "Permanent" :
      return cms.btn_permanent;
    case "Temporary":
      return cms.btn_temporary;
    case "No current housing": 
      return cms.btn_no_current;
    default:
      return option;
  }
};


// 'list-remove-reason' cms
export const translateCareTeamReasons= (incomingReason="", cms) => {
  if(!cms) {
    return incomingReason;
  }

  let reason = incomingReason?.toLowerCase() ?? "";
  switch (reason) {
    case "service was disconnected":
      return cms.service_disconnected;
    case "completed needed services": 
      return cms.completed;
    case "no current housing": 
      return cms.no_current_housing;
    case "member deactivated their account":
      return cms.member_deactivated;
    case "dissatisfied with services":
      return cms.dissatisfied;
    case "moved out of provider area": 
      return cms.moved_out;
    case "cost of services too high":
      return cms.cost;
    case "prefer not to disclose":
      return cms.prefer_not;
    case "other":
      return cms.other;
    default:
      return incomingReason;
  }
};

export const cmsReplace = (cmsMessage, toReplace, replaceWith) => {
  return cmsMessage.replace(toReplace, replaceWith)
}


/* ====================================================== */ 


export const validateUrl = (url) => {
  const matches = url.match(/((<\/(a-Z)*\b[^>]*>)|(<(a-Z)*\b[^>]*>))/);
  if (matches) {
    return false;
  } else {
     return url;     
  }
}


export const handleNameField = (e, fieldName, setInvalidState, pattern) => {
  e.target.value = e.target.value.replace( / +/g, ' ' );
  if(e.target.value === ' ') {
    e.target.value = ''
  }
  if(!pattern) {
    pattern = (e) => e.target.value !== "" && !isValidName(e.target.value);
  }
  if(setInvalidState) {
    setInvalidState((state) => ({
      ...state,
      [fieldName]: pattern(e)
    }));
  }
}

/**
 * Checks if a given name is valid according to specified criteria.
 * < 50 characters
 * character of any language (can contain unicode) or space, -, comma, single quote
 *
 * @param {string} name - The name to be validated.
 * @returns {boolean} True if the name is valid; otherwise, false.
 */
export const isValidName = (name) => {
  const validNameRegex = /^[\p{L}\p{M}\s\-.']+$/u;
  let validCharacterLength = false;
  let validRegex = validNameRegex.test(name);
  
  if (name.length <= 50) validCharacterLength = true;
  
  if (validCharacterLength && validRegex) {
    return true;
  }
  
  return false;
}

// Zip Code formatting for Elastic Search
export const cleanZipFormatting = (stringToClean) => {
  let cleanedString = stringToClean;
  let stringToRemove = stringToClean?.match(/<zip>(.*?)<\/zip>/g);
  if (stringToRemove) {
    cleanedString = stringToClean.replace(stringToRemove, "");
  }
  return cleanedString;
};

export const extractZipFromSearch = (searchTerm = "") => {
  if (searchTerm.includes("<zip></zip>")) return "";
  let zipIndex = searchTerm.indexOf("<zip>");
  if (zipIndex !== -1) {
    return searchTerm.slice(zipIndex + 5, -6);
  }
  return "";
};

export const handleCommunicationPreference = (preference) => {
  if (preference === "mobile" || preference === "text") {
    return "text";
  }
  if (preference === "home" || preference === "phone") {
    return "phone";
  }
  if (preference === "email") {
    return "email";
  }
};

export const getProviderUrl = (path = '') => {
  let providerCreateAccountUrl = process.env.REACT_APP_PROVIDER_URL;
  if (path) {
    providerCreateAccountUrl = `${providerCreateAccountUrl}/${path}`;
  }
  providerCreateAccountUrl = validateUrl(providerCreateAccountUrl) || "#";
  providerCreateAccountUrl = DOMPurify.sanitize(providerCreateAccountUrl)
  return providerCreateAccountUrl;
};


// Common util to handle logging out
export const handleLogoutUtil = ({ channel, setLoadingLogout, clearUser, history, shouldSetLoadingLogoutToFalse = true, postMessage = true }) => {
  setLoadingLogout(true);
  if(postMessage === true) {
    channel.postMessage({ key: "logout" }); // Notify other tabs to log out
  }
  clearUser();
  sessionStorage.removeItem("penn-user");
  let locale = localStorage.getItem('locale');
  localStorage.clear();
  if (locale) {
    localStorage.setItem("locale", locale);
  }
  if(history) {
    history.push("/login");
  } else {
    window.location.href = '/login';  
  }
  if(shouldSetLoadingLogoutToFalse) {
    setTimeout(() => {
      setLoadingLogout(false);
    }, "2000");
  }
};
