import React from "react";
import styled from "styled-components";

// can now also be used on other components
// sizes to the parent container
export const Fallback = () => (
  <Container>
    <div
      className="bx--loading-overlay"
      style={{
        position: "absolute",
        height: "100vh"
      }}
    >
      <div data-loading className="bx--loading">
        <svg className="bx--loading__svg" viewBox="0 0 100 100">
          <title>Loading</title>
          <circle className="bx--loading__stroke" cx="50%" cy="50%" r="44" />
        </svg>
      </div>
    </div>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
