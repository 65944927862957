import { Locked32 } from "@carbon/icons-react";
import styled from "styled-components";
import { Modal as Ml, ModalBody as MB } from "carbon-components-react";

import { Link } from "react-router-dom";

export const Modal = styled(Ml)`
  & .bx--modal-close {
    display: none;
  }
  // comment out to enable vertical scroll
  & .bx--modal-content {
    max-height: none;
    overflow-y: hidden;
  }

  & p {
    padding-right: 0;
  }
`;

export const LockIcon = styled.div`
  width: 100%;
  height: 70vh !important;
  height: auto;
  display: grid;
  place-items: center;
  @media (min-width: 768px) {
    height: 20rem !important;
  }
`;

export const Locked = styled(Locked32)`
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const ViewContainer = styled.a`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  & svg {
    fill: #000;
  }
  & h4 {
    color: #000;
  }
  path {
    transition: fill 0.2s ease;
  }
  h4 {
    transition-delay: 50ms;
    transition-property: color;
    transition-timing-function: ease;
    transition-duration: 150ms;
  }
  &:hover {
    & svg {
      fill: #4ac1e0;
    }
    & h4 {
      color: #33a7c2;
    }
  }

  &:focus {
    & svg {
      fill: #4ac1e0;
    }
    & h4 {
      color: #33a7c2;
    }
  }
`;

export const ModalBody = styled(MB)`
  padding-bottom: 0;
  max-height: none;
`;
